import React, { useEffect, useState , useContext} from "react";
import { Link} from "react-router-dom";
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { Spinner } from "react-bootstrap";
import $ from 'jquery';
import { useParams, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import UrlHouse from "../../utils/UrlHouse";
import DefaultProfile from '../../asset/default_profile.png';
import VerifiedBadge from '../../asset/verified_badge.png';
import { AppContext } from "../../utils/AppContext";
import Logo from '../../asset/logo_long_transparent_cropped.png';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import {Helmet} from "react-helmet";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const DashboardBalance = () => {
    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = useState(false);
    const appContext = useContext(AppContext);

    const [currentBalance, setCurrentBalance] = useState(0);
    const [transactionHistory, setTransactionHistory] = useState([]);

    const desktopStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px",
        },

        createAdButton: {
            textDecoration: "none"
        }
    }

    const mobileStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px",
        },
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    const [linkPreview, setLinkPreview] = useState({
        title: "Woilo - Photo, Video, Chat, NFTs",
        description: "Woilo - Photo, Video, Chat, NFTs",
        url: "",
        imageUrl: ""
    })

    useEffect(() => {
        init();
    }, [])

    const init = () => {
        $.ajax({
            type: "POST",
            url: UrlHouse.DASHBOARD_BALANCE,
            data: JSON.stringify({
                email: localStorage.getItem("email"), password: localStorage.getItem("password")
            }),
            dataType: "json",
            success: function (response) {
                if(response.result === 1){
                    setIsLoaded(true);
                    setCurrentBalance(response.balance);
                    let tempArr = [];
                    response.transaction_history.forEach(transaction => {
                        let tmp = {
                            transactionName: transaction.transaction_name,
                            transactionAmount: transaction.transaction_amount,
                            transactionType: transaction.transaction_type,
                            transactionDate: transaction.transaction_date
                        }
                        tempArr.push(tmp);
                    });
                    setTransactionHistory(tempArr);
                }else{
                    navigate('/login');
                }
            },
            error: function(e){
                navigate('/login');
            }
        });
    }

    function getCurrentBalance(){
        const formatter = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        });
        return formatter.format(currentBalance);
    }

    function convertIdr(amount){
        const formatter = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        });
        return formatter.format(amount);
    }

    return(
        <>
            <div style={styles.outerContainerStyle}>
                <Helmet>
                    <title>{linkPreview.title}</title>

                    <meta property="og:title" content={linkPreview.title} />
                    <meta property="og:description" content={linkPreview.description} />
                    <meta property="og:image" content={linkPreview.imageUrl} />
                    <meta property="og:url" content={linkPreview.url} />
                    <meta property="og:type" content="website"/>

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:domain" content="woilo.com" />
                    <meta property="twitter:url" content={linkPreview.url} />
                    <meta name="twitter:title" content={linkPreview.title} />
                    <meta name="twitter:description" content={linkPreview.description} />
                    <meta name="twitter:image" content={linkPreview.imageUrl} />
                </Helmet>
                {
                    <div style={styles.mainContainerStyle}>

                        <h1>Saldo Iklan</h1>
                        <hr></hr>
                        <Form>
                            <Form.Group as={Row} className="mb-3">
                                <Col sm={{ span: 12}} style={{display: "flex"}}>
                                    <Button onClick={() => {navigate("/dashboard/balance/topup")}} style={styles.sendButtonStyle}>Tambah Saldo</Button>
                                </Col>
                            </Form.Group>
                        </Form>
                        <h2>{getCurrentBalance()}</h2>
                        <p>Saldo Iklan ini akan dipakai saat kamu membuat iklan baru.</p>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }} aria-label="simple table">
                                <TableHead>
                                <TableRow>
                                    <TableCell>Keterangan Transaksi</TableCell>
                                    <TableCell align="right">Nominal Transaksi</TableCell>
                                    <TableCell align="right">Tanggal Transaksi</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {transactionHistory.map((transaction) => (
                                    <TableRow
                                    key={transaction.transactionName}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                    <TableCell component="th" scope="row">
                                        {transaction.transactionName}
                                    </TableCell>
                                    <TableCell align="right" style={{color: transaction.transactionType==="add"?"green":"red"}}>{convertIdr(transaction.transactionAmount)}</TableCell>
                                    <TableCell align="right">{transaction.transactionDate}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    
                }
            </div>
        </>
    )
}

export default DashboardBalance;