import React, { useEffect, useState , useContext} from "react";
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { Link} from "react-router-dom";
import { Spinner } from "react-bootstrap";
import $ from 'jquery';
import { useParams, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import UrlHouse from "../../../utils/UrlHouse";
import DefaultProfile from '../../../asset/default_profile.png';
import VerifiedBadge from '../../../asset/verified_badge.png';
import { AppContext } from "../../../utils/AppContext";
import Logo from '../../../asset/logo_long_transparent_cropped.png';
import "../../../spinner.css";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import {Helmet} from "react-helmet";
import axios from 'axios';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip} from 'recharts';

const AdDetailPage = () => {

    const appContext = useContext(AppContext);
    const navigate = useNavigate();
    const params = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const [adData, setAdData] = useState({
        adName: "",
        adType: 101,
        adStatus: 0,
        startDate: "",
        endDate: "",
        adBudget: 0,
        adSpending: 0,
        adBudgetRemaining: 0,
        adViewCount: 0,
        adClickCount: 0,
    })

    const [adMedia, setAdMedia] = useState({
        adDescription: "",
        adCallToAction: "",
        adCallToActionDestination: "",
        imageContents: [],
        videoContents: []
    })

    const [adViewList, setAdViewList] = useState([]);
    const [adClickList, setAdClickList] = useState([]);

    const desktopStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px"
        },

        subContainerStyle: {
            width: "100%",
            display: "flex"
        },

        selectImageButtonStyle: {
            display: "none"
        },

        imagePreviewStyle: {
            height: "30vh",
            margin: "10px"
        },

        sendButtonStyle: {
            
        }
    }

    const mobileStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px",
        },

        subContainerStyle: {
            width: "100%"
        },

        selectImageButtonStyle: {
            display: 'none'
        },

        imagePreviewStyle: {
            height: "30vh",
            margin: "10px"
        },

        sendButtonStyle: {
            
        }
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    useEffect(() => {
      init();
    }, [])

    const init = () => {
        $.ajax({
            type: "POST",
            url: UrlHouse.DASHBOARD_AD_DETAIL_INIT,
            data: JSON.stringify({
                email: localStorage.getItem("email"), password: localStorage.getItem("password"), ad_id: params.id
            }),
            dataType: "json",
            success: function (response) {
                if(response.result === 1){
                    setIsLoaded(true);
                    setAdData({
                        adName: response.ad_name,
                        adType: response.ad_type,
                        adStatus: response.ad_status,
                        startDate: response.start_date,
                        endDate: response.end_date,
                        adBudget: response.ad_budget,
                        adSpending: response.ad_spending,
                        adBudgetRemaining: response.ad_budget_remaining,
                        adViewCount: response.ad_view_count,
                        adClickCount: response.ad_click_count,
                    });

                    let imageContents = [];
                    response.image_contents.forEach(image => {
                        imageContents.push(image);
                    });

                    let videoContents = [];
                    response.video_contents.forEach(video => {
                        videoContents.push(video);
                    });

                    setAdMedia({
                        adDescription: response.ad_description,
                        adCallToAction: response.ad_call_to_action,
                        adCallToActionDestination: response.ad_call_to_action_destination,
                        imageContents: imageContents,
                        videoContents: videoContents
                    })

                    let viewList = [];
                    response.ad_view_list.forEach(view => {
                        let tmp = {
                            "date": view.date,
                            "view": view.view
                        }
                        viewList.push(tmp);
                    });
                    setAdViewList(viewList);

                    let clickList = [];
                    response.ad_click_list.forEach(click => {
                        let tmp = {
                            "date": click.date,
                            "click": click.click
                        }
                        clickList.push(tmp);
                    });
                    setAdClickList(clickList);

                }else{
                    navigate('/dashboard/ads/create/confirm/'+params.id);
                }
            },
            error: function(e){
                navigate('/dashboard/ads');
            }
        });
    }

    const [linkPreview, setLinkPreview] = useState({
        title: "Woilo - Photo, Video, Chat, NFTs",
        description: "Woilo - Photo, Video, Chat, NFTs",
        url: "",
        imageUrl: ""
    })

    function getAdType(){
        if(adData.adType === 101){
            return "Iklan Foto";
        }
        if(adData.adType === 102){
            return "Iklan Video";
        }
        if(adData.adType === 103){
            return "Iklan Karosel";
        }
        return "Iklan Foto";
    }

    function getAdBudget(){
        const formatter = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        });
        return formatter.format(adData.adBudget);
    }

    function getAdSpending(){
        const formatter = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        });
        return formatter.format(adData.adSpending);
    }

    function getAdBudgetRemaining(){
        const formatter = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        });
        return formatter.format(adData.adBudgetRemaining);
    }

    function getAdStatus(){
        //ad_status
        //0 = waiting for payment
        //1 = waiting for review
        //2 = ad running
        //3 = ad finished
        if(adData.adStatus === 0){
            return (
                <div>
                    <h6 style={{color: appContext.colors.primaryGrey}}>Menunggu Pembayaran</h6>
                    <Form>
                        <Form.Group as={Row} className="mb-3">
                            <Col sm={{ span: 12}} style={{display: "flex"}}>
                                <Button onClick={() => {navigate("/dashboard/ads/create/invoice/"+params.id)}} style={styles.sendButtonStyle}>Selesaikan Pembayaran</Button>
                            </Col>
                        </Form.Group>
                    </Form>
                </div>
            );
        }else if(adData.adStatus === 1){
            return (
                <h6 style={{color: appContext.colors.primaryGrey}}>Sedang ditinjau Tim WoiloAds</h6>
            );
        }else if(adData.adStatus === 2){
            return (
                <div>
                    <h6 style={{color: "green"}}>Iklan Berjalan</h6>
                    <Form>
                        <Form.Group as={Row} className="mb-3">
                            <Col sm={{ span: 12}} style={{display: "flex"}}>
                                <Button onClick={() => {stopAd()}} disabled={isLoading} style={{backgroundColor: "red", borderColor: "red"}}>Berhentikan Iklan</Button>
                                {isLoading?<div className="loading-spinner" style={{marginTop: "5px", marginLeft: "10px"}}></div>:<></>}
                            </Col>
                        </Form.Group>
                    </Form>
                </div>
            );
        }else if(adData.adStatus === 3){
            return (
                <div>
                    <h6 style={{color: "red"}}>Iklan Selesai</h6>
                    <Form>
                        <Form.Group as={Row} className="mb-3">
                            <Col sm={{ span: 12}} style={{display: "flex"}}>
                                <Button onClick={() => {deleteAd()}} disabled={isLoading} style={{backgroundColor: "white", borderColor: "red", color: "red"}}>Hapus Iklan</Button>
                                {isLoading?<div className="loading-spinner" style={{marginTop: "5px", marginLeft: "10px"}}></div>:<></>}
                            </Col>
                        </Form.Group>
                    </Form>
                </div>
            );
        }else if(adData.adStatus === 4){
            return (
                <div>
                    <h6 style={{color: "red"}}>Iklan Ditolak</h6>
                    <Form>
                        <Form.Group as={Row} className="mb-3">
                            <Col sm={{ span: 12}} style={{display: "flex"}}>
                                <Button onClick={() => {deleteAd()}} disabled={isLoading} style={{backgroundColor: "white", borderColor: "red", color: "red"}}>Hapus Iklan</Button>
                                {isLoading?<div className="loading-spinner" style={{marginTop: "5px", marginLeft: "10px"}}></div>:<></>}
                            </Col>
                        </Form.Group>
                    </Form>
                </div>
            );
        }
        return (<></>);
    }

    function stopAd(){
        confirmAlert({
            title: 'Berhentikan Iklan?',
            message: 'Kamu yakin ingin menghentikan iklan ini?',
            buttons: [
                {
                    label: 'Hentikan Iklan',
                    onClick: () => {
                        setIsLoading(true);
                        $.ajax({
                            type: "POST",
                            url: UrlHouse.DASHBOARD_STOP_AD,
                            data: JSON.stringify({
                                email: localStorage.getItem("email"), password: localStorage.getItem("password"), ad_id: params.id
                            }),
                            dataType: "json",
                            success: function (response) {
                                setIsLoading(false);
                                if(response.result === 1){
                                    setIsLoaded(false);
                                    alert("Iklan berhasil dihentikan")
                                    init();
                                }else{
                                    alert("Ups, terjadi kesalahan. Coba lagi nanti")
                                }
                            },
                            error: function(e){
                                setIsLoading(false);
                                alert("Ups, terjadi kesalahan. Coba lagi nanti")
                            }
                        });
                    }
                },
                {
                    label: 'Batalkan',
                    onClick: () => {}
                }
            ]
        });
    }

    function deleteAd(){
        confirmAlert({
            title: 'Hapus Iklan?',
            message: 'Kamu yakin ingin menghapus iklan ini?',
            buttons: [
                {
                    label: 'Hapus Iklan',
                    onClick: () => {
                        setIsLoading(true);
                        $.ajax({
                            type: "POST",
                            url: UrlHouse.DASHBOARD_DELETE_AD,
                            data: JSON.stringify({
                                email: localStorage.getItem("email"), password: localStorage.getItem("password"), ad_id: params.id
                            }),
                            dataType: "json",
                            success: function (response) {
                                setIsLoading(false);
                                if(response.result === 1){
                                    alert("Iklan berhasil dihapus")
                                    navigate('/dashboard/ads');
                                }else{
                                    alert("Ups, terjadi kesalahan. Coba lagi nanti")
                                }
                            },
                            error: function(e){
                                setIsLoading(false);
                                alert("Ups, terjadi kesalahan. Coba lagi nanti")
                            }
                        });
                    }
                },
                {
                    label: 'Batalkan',
                    onClick: () => {}
                }
            ]
        });
    }

    function getAdViewChart(){
        return (
            <div style={{marginTop: "20px"}}>
                <h3>Tayangan</h3>
                <h6>{formatNumber(adData.adViewCount)} Tayangan</h6>
                <label>Grafik ini menunjukkan jumlah tayangan iklan selama 7 hari terakhir</label>
                <LineChart
                    width={appContext.mobileMode?window.innerWidth*8/10:window.innerWidth*2/5}
                    height={appContext.mobileMode?window.innerWidth/2:window.innerWidth/6}
                    data={adViewList}
                    padding={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    >
                    <CartesianGrid strokeDasharray="1 1" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="view" stroke="#8884d8" activeDot={{ r: 8 }} />
                </LineChart>
            </div>
        );
    }

    function getAdClickChart(){
        return (
            <div style={{marginTop: "20px"}}>
                <h3>Klik</h3>
                <h6>{formatNumber(adData.adClickCount)} Klik</h6>
                <label>Grafik ini menunjukkan jumlah klik iklan selama 7 hari terakhir</label>
                <LineChart
                    width={appContext.mobileMode?window.innerWidth*8/10:window.innerWidth*2/5}
                    height={appContext.mobileMode?window.innerWidth/2:window.innerWidth/6}
                    data={adClickList}
                    padding={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    >
                    <CartesianGrid strokeDasharray="1 1" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="click" stroke="#8884d8" activeDot={{ r: 8 }} />
                </LineChart>
            </div>
        );
    }

    function formatNumber(number){
        const formatter = new Intl.NumberFormat('id-ID', {
            
        });
        return formatter.format(number);
    }

    function getImageContent(url){
        return (
            <>
                <img src={url} style={{height: window.innerHeight/5, marginRight: "10px", marginBottom: "10px"}} alt="content"/>
            </>
        );
    }

    function getVideoContent(url){
        return (
            <>
                <video style={{height: window.innerHeight/5, marginRight: "10px", marginBottom: "10px"}} playsInline controls>
                    <source src={url} type="video/mp4"/>
                </video>
            </>
        );
    }

    function getAdContents(){
        return (
            <>
                {adMedia.imageContents.length > 0 && adMedia.imageContents.map((item) => getImageContent(item))}
                {adMedia.videoContents.length > 0 && adMedia.videoContents.map((item) => getVideoContent(item))}
            </>
        );
    }

    const onBack = () => {
        navigate('/dashboard/ads')
    }

    return(
        <>
            <div style={styles.outerContainerStyle}>
                <Helmet>
                    <title>{linkPreview.title}</title>

                    <meta property="og:title" content={linkPreview.title} />
                    <meta property="og:description" content={linkPreview.description} />
                    <meta property="og:image" content={linkPreview.imageUrl} />
                    <meta property="og:url" content={linkPreview.url} />
                    <meta property="og:type" content="website"/>

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:domain" content="woilo.com" />
                    <meta property="twitter:url" content={linkPreview.url} />
                    <meta name="twitter:title" content={linkPreview.title} />
                    <meta name="twitter:description" content={linkPreview.description} />
                    <meta name="twitter:image" content={linkPreview.imageUrl} />
                </Helmet>
                {
                    isLoaded?
                        appContext.mobileMode?
                        <div style={styles.mainContainerStyle}>
                            <div>
                                <div>
                                    <Form>
                                        <Form.Group as={Row} className="mb-3">
                                            <Col sm={{ span: 12}}>
                                                <Button onClick={onBack} style={{backgroundColor: appContext.colors.primaryWhite, color: appContext.colors.primaryBlue}}>Kembali</Button>
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <div>
                                    <h1>{adData.adName}</h1>
                                    <label>{getAdType()}</label>
                                </div>
                            </div>
                            <hr></hr>
                            <div style={styles.subContainerStyle}>
                                <div style={{marginTop: "10px"}}>
                                    <h3>Pengaturan Iklan</h3>
                                    <h5>Status Iklan</h5>
                                    {getAdStatus()}
                                    <h5>Tanggal Mulai</h5>
                                    <h6 style={{color: appContext.colors.primaryGrey}}>{adData.startDate}</h6>
                                    <h5>Tanggal Selesai</h5>
                                    <h6 style={{color: appContext.colors.primaryGrey}}>{adData.endDate}</h6>
                                </div>
                                <div style={{marginTop: "10px"}}>
                                    <h3>Biaya Iklan</h3>
                                    <h5>Dana Iklan</h5>
                                    <h6 style={{color: appContext.colors.primaryGrey}}>{getAdBudget()}</h6>
                                    <h5>Dana Terpakai</h5>
                                    <h6 style={{color: appContext.colors.primaryGrey}}>{getAdSpending()}</h6>
                                    <h5>Dana Tersisa</h5>
                                    <h6 style={{color: appContext.colors.primaryGrey}}>{getAdBudgetRemaining()}</h6>
                                </div>
                            </div>
                            <div style={styles.subContainerStyle}>
                                <div>
                                    {getAdViewChart()}
                                </div>
                                <div>
                                    {getAdClickChart()}
                                </div>
                            </div>
                            <div style={{marginTop: "20px", width: "100%"}}>
                                <h3>Media Iklan</h3>
                                <h5>Deskripsi</h5>
                                <h6 style={{color: appContext.colors.primaryGrey}}>{adMedia.adDescription}</h6>
                                <h5>Tombol Aksi</h5>
                                <h6 style={{color: appContext.colors.primaryGrey}}>{adMedia.adCallToAction}</h6>
                                <h5>Link Tujuan</h5>
                                <h6 style={{color: appContext.colors.primaryGrey}}>{adMedia.adCallToActionDestination}</h6>
                                <h5>Konten Iklan</h5>
                                {getAdContents()}
                            </div>
                        </div>
                        :
                        <div style={styles.mainContainerStyle}>
                            <div>
                                <div>
                                    <Form>
                                        <Form.Group as={Row} className="mb-3">
                                            <Col sm={{ span: 12}}>
                                                <Button onClick={onBack} style={{backgroundColor: appContext.colors.primaryWhite, color: appContext.colors.primaryBlue}}>Kembali</Button>
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <div>
                                    <h1>{adData.adName}</h1>
                                    <label>{getAdType()}</label>
                                </div>
                            </div>
                            <hr></hr>
                            <div style={styles.subContainerStyle}>
                                <div style={{flex: "1", float: "left", marginRight: "10px", marginTop: "10px"}}>
                                    <h3>Pengaturan Iklan</h3>
                                    <h5>Status Iklan</h5>
                                    {getAdStatus()}
                                    <h5>Tanggal Mulai</h5>
                                    <h6 style={{color: appContext.colors.primaryGrey}}>{adData.startDate}</h6>
                                    <h5>Tanggal Selesai</h5>
                                    <h6 style={{color: appContext.colors.primaryGrey}}>{adData.endDate}</h6>
                                </div>
                                <div style={{flex: "1", float: "left", marginTop: "10px"}}>
                                    <h3>Biaya Iklan</h3>
                                    <h5>Dana Iklan</h5>
                                    <h6 style={{color: appContext.colors.primaryGrey}}>{getAdBudget()}</h6>
                                    <h5>Dana Terpakai</h5>
                                    <h6 style={{color: appContext.colors.primaryGrey}}>{getAdSpending()}</h6>
                                    <h5>Dana Tersisa</h5>
                                    <h6 style={{color: appContext.colors.primaryGrey}}>{getAdBudgetRemaining()}</h6>
                                </div>
                            </div>
                            <div style={styles.subContainerStyle}>
                                <div style={{flex: "1", float: "left"}}>
                                    {getAdViewChart()}
                                </div>
                                <div style={{flex: "1", float: "left"}}>
                                    {getAdClickChart()}
                                </div>
                            </div>
                            <div style={{marginTop: "20px", width: "100%"}}>
                                <h3>Media Iklan</h3>
                                <h5>Deskripsi</h5>
                                <h6 style={{color: appContext.colors.primaryGrey}}>{adMedia.adDescription}</h6>
                                <h5>Tombol Aksi</h5>
                                <h6 style={{color: appContext.colors.primaryGrey}}>{adMedia.adCallToAction}</h6>
                                <h5>Link Tujuan</h5>
                                <h6 style={{color: appContext.colors.primaryGrey}}>{adMedia.adCallToActionDestination}</h6>
                                <h5>Konten Iklan</h5>
                                {getAdContents()}
                            </div>
                        </div>
                    :
                    <></>
                    
                }
            </div>
        </>
    )
}

export default AdDetailPage;