import React, { useEffect, useState , useContext} from "react";
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { Link} from "react-router-dom";
import { Spinner } from "react-bootstrap";
import $ from 'jquery';
import { useParams, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import UrlHouse from "../../utils/UrlHouse";
import DefaultProfile from '../../asset/default_profile.png';
import VerifiedBadge from '../../asset/verified_badge.png';
import { AppContext } from "../../utils/AppContext";
import Logo from '../../asset/logo_long_transparent_cropped.png';
import "../../spinner.css";

import {Helmet} from "react-helmet";
import { jsPDF } from "jspdf";
import * as html2canvas from 'html2canvas';
import axios from 'axios';
import { Label } from "recharts";
import WoiloLogo from '../../asset/logo_long_transparent_cropped.png';

const InvoiceMessagePage = () => {

    const appContext = useContext(AppContext);
    const navigate = useNavigate();
    const params = useParams();
    const [isLoaded, setIsLoaded] = useState(false);

    const [invoiceData, setInvoiceData] = useState({
        advertiserName: "",
        invoiceId: "",
        invoiceDate: "",
        invoiceName: "",
        invoiceDescription: "",
        invoicePrice: 0,
        invoiceTax: 0,
        invoiceDiscount: 0,
        invoiceTotalPrice: 0
    })

    const desktopStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px"
        },

        selectImageButtonStyle: {
            display: "none"
        },

        imagePreviewStyle: {
            height: "30vh",
            margin: "10px"
        },

        sendButtonStyle: {
            
        },

        invoiceContainer: {
            width: (window.innerWidth*4/5 - 60)+"px",
            marginBottom: "10px"
        }
    }

    const mobileStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px",
        },

        selectImageButtonStyle: {
            display: 'none'
        },

        imagePreviewStyle: {
            height: "30vh",
            margin: "10px"
        },

        sendButtonStyle: {
            
        },

        invoiceContainer: {
            width: (window.innerWidth - 60)+"px",
            marginBottom: "10px"
        }
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    useEffect(() => {
      init();
    }, [])

    const init = () => {
        $.ajax({
            type: "POST",
            url: UrlHouse.INVOICE_MESSAGE_INIT,
            data: JSON.stringify({
                email: localStorage.getItem("email"), password: localStorage.getItem("password"), message_id: params.id
            }),
            dataType: "json",
            success: function (response) {
                if(response.result === 1){
                    setIsLoaded(true);
                    setInvoiceData({
                        advertiserName: response.advertiser_name,
                        invoiceId: response.invoice_id,
                        invoiceDate: response.invoice_date,
                        invoiceName: response.invoice_name,
                        invoiceDescription: response.invoice_description,
                        invoicePrice: response.invoice_price,
                        invoiceTax: response.invoice_tax,
                        invoiceDiscount: response.invoice_discount,
                        invoiceTotalPrice: response.invoice_total_price
                    });
                    if(response.invoice_total_price === 0){
                        navigate('/dashboard/message_ads/create/payment/'+params.id);
                    }
                }else{
                    navigate('/dashboard/message_ads/create/confirm/'+params.id);
                }
            },
            error: function(e){
                navigate('/dashboard/message_ads');
            }
        });
    }

    const [linkPreview, setLinkPreview] = useState({
        title: "Woilo - Photo, Video, Chat, NFTs",
        description: "Woilo - Photo, Video, Chat, NFTs",
        url: "",
        imageUrl: ""
    })

	const onSubmitButtonClick = async() => {
        navigate('/dashboard/message_ads/create/payment/'+params.id);
	};

    const onBack = () => {
        navigate('/dashboard/message_ads')
    }

    function convertToIdr(price){
        if(price === 0){
            return "-";
        }
        const formatter = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        });
        return formatter.format(price);
    }

    function downloadInvoice() {
        const input = document.getElementById('invoice');

        html2canvas(input)
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            if(appContext.mobileMode){
                const targetWidth = 180;
                const targetHeight = canvas.height * targetWidth/canvas.width;
                pdf.addImage(imgData, 'JPEG', 13, 10, targetWidth, targetHeight);
            }else{
                const targetWidth = 240;
                const targetHeight = canvas.height * targetWidth/canvas.width;
                pdf.addImage(imgData, 'JPEG', 10, 10, targetWidth, targetHeight);
            }
            pdf.save("WoiloAds - " + invoiceData.invoiceId+".pdf");
          })
        ;
      }

    return(
        <>
            <div style={styles.outerContainerStyle}>
                <Helmet>
                    <title>{linkPreview.title}</title>

                    <meta property="og:title" content={linkPreview.title} />
                    <meta property="og:description" content={linkPreview.description} />
                    <meta property="og:image" content={linkPreview.imageUrl} />
                    <meta property="og:url" content={linkPreview.url} />
                    <meta property="og:type" content="website"/>

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:domain" content="woilo.com" />
                    <meta property="twitter:url" content={linkPreview.url} />
                    <meta name="twitter:title" content={linkPreview.title} />
                    <meta name="twitter:description" content={linkPreview.description} />
                    <meta name="twitter:image" content={linkPreview.imageUrl} />
                </Helmet>
                {
                    isLoaded?<div style={styles.mainContainerStyle}>
                        <h1>Pembayaran Iklan</h1>
                        <hr></hr>
                        <div id="invoice">
                            {
                            appContext.mobileMode?
                                <div style={styles.invoiceContainer}>
                                    <img src={WoiloLogo}  style={{marginTop: "20px"}} alt="logo" width="150"></img>
                                    <div style={{display: "flex", marginTop: "20px"}}>
                                        <div style={{flex: "1"}}>
                                            <h5>Invoice(Faktur) ditujukan kepada</h5>
                                            <h2 style={{color: appContext.colors.primaryBlue}}>{invoiceData.advertiserName}</h2>
                                        </div>
                                    </div>
                                    <div style={{display: "flex"}}>
                                        <div style={{flex: "1"}}>
                                        <h5>Invoice : {invoiceData.invoiceId}</h5>
                                        </div>
                                        <div style={{flex: "1", textAlign: "right"}}>
                                            <h6>Tanggal : {invoiceData.invoiceDate}</h6>
                                        </div>
                                    </div>
                                    <div style={{width: "100%", height: "2px", backgroundColor: appContext.colors.primaryBlue}}></div>
                                </div>
                                :
                                <div style={styles.invoiceContainer}>
                                    <img src={WoiloLogo}  style={{marginTop: "20px"}} alt="logo" width="200"></img>
                                    <div style={{display: "flex", marginTop: "20px"}}>
                                        <div style={{flex: "1"}}>
                                            <h5>Invoice(Faktur) ditujukan kepada</h5>
                                            <h2 style={{color: appContext.colors.primaryBlue}}>{invoiceData.advertiserName}</h2>
                                        </div>
                                    </div>
                                    <div style={{display: "flex"}}>
                                        <div style={{flex: "1"}}>
                                        <h5>Invoice : {invoiceData.invoiceId}</h5>
                                        </div>
                                        <div style={{flex: "1", textAlign: "right"}}>
                                            <h6>Tanggal : {invoiceData.invoiceDate}</h6>
                                        </div>
                                    </div>
                                    <div style={{width: "100%", height: "2px", backgroundColor: appContext.colors.primaryBlue}}></div>
                                </div>
                            }
                            {
                            appContext.mobileMode?
                                <div style={styles.invoiceContainer}>
                                    <div style={{display: "flex"}}>
                                        <div style={{flex: "1"}}>
                                            <h6>{invoiceData.invoiceName}</h6>
                                            <h6 style={{fontWeight: "300"}}>{invoiceData.invoiceDescription}</h6>
                                        </div>
                                        <div style={{flex: "1", textAlign: "right"}}>
                                            <h5 style={{fontWeight: "400"}}>{convertToIdr(invoiceData.invoicePrice)}</h5>
                                        </div>
                                    </div>
                                    <div style={{display: "flex"}}>
                                        <div style={{flex: "1"}}>
                                            <h6>PPN 11% (Pajak Penambahan Nilai)</h6>
                                        </div>
                                        <div style={{flex: "1", textAlign: "right"}}>
                                            <h5 style={{fontWeight: "400"}}>{convertToIdr(invoiceData.invoiceTax)}</h5>
                                        </div>
                                    </div>
                                    <div style={{display: "flex"}}>
                                        <div style={{flex: "1"}}>
                                            <h6>Diskon atau Potongan Harga</h6>
                                        </div>
                                        <div style={{flex: "1", textAlign: "right"}}>
                                            <h5 style={{fontWeight: "400"}}>{convertToIdr(invoiceData.invoiceDiscount)}</h5>
                                        </div>
                                    </div>
                                    <div style={{width: "100%", height: "2px", backgroundColor: appContext.colors.primaryBlue}}></div>
                                    <div style={{display: "flex", marginTop: "10px"}}>
                                        <div style={{flex: "1"}}>
                                            <h5>Total Pembayaran</h5>
                                        </div>
                                        <div style={{flex: "1", textAlign: "right"}}>
                                            <h5>{convertToIdr(invoiceData.invoiceTotalPrice)}</h5>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div style={styles.invoiceContainer}>
                                    <div style={{display: "flex"}}>
                                        <div style={{flex: "1"}}>
                                            <h6>{invoiceData.invoiceName}</h6>
                                            <h6 style={{fontWeight: "300"}}>{invoiceData.invoiceDescription}</h6>
                                        </div>
                                        <div style={{flex: "1", textAlign: "right"}}>
                                            <h5 style={{fontWeight: "400"}}>{convertToIdr(invoiceData.invoicePrice)}</h5>
                                        </div>
                                    </div>
                                    <div style={{display: "flex"}}>
                                        <div style={{flex: "1"}}>
                                            <h6>PPN 11% (Pajak Penambahan Nilai)</h6>
                                        </div>
                                        <div style={{flex: "1", textAlign: "right"}}>
                                            <h5 style={{fontWeight: "400"}}>{convertToIdr(invoiceData.invoiceTax)}</h5>
                                        </div>
                                    </div>
                                    <div style={{display: "flex"}}>
                                        <div style={{flex: "1"}}>
                                            <h6>Diskon atau Potongan Harga</h6>
                                        </div>
                                        <div style={{flex: "1", textAlign: "right"}}>
                                            <h5 style={{fontWeight: "400"}}>{convertToIdr(invoiceData.invoiceDiscount)}</h5>
                                        </div>
                                    </div>
                                    <div style={{width: "100%", height: "2px", backgroundColor: appContext.colors.primaryBlue}}></div>
                                    <div style={{display: "flex", marginTop: "10px"}}>
                                        <div style={{flex: "1"}}>
                                            <h5>Total Pembayaran</h5>
                                        </div>
                                        <div style={{flex: "1", textAlign: "right"}}>
                                            <h5>{convertToIdr(invoiceData.invoiceTotalPrice)}</h5>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                        <div>
                            <Form>
                                <Form.Group as={Row} className="mb-3">
                                    <Col sm={{ span: 12}} style={{display: "flex"}}>
                                        <Button onClick={downloadInvoice} style={styles.sendButtonStyle}>Unduh Invoice/Faktur</Button>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </div>

                        <div>
                            <Form>
                                <Form.Group as={Row} className="mb-3">
                                    <Col sm={{ span: 12}} style={{display: "flex"}}>
                                        <Button onClick={onSubmitButtonClick} style={styles.sendButtonStyle}>Selesaikan Pembayaran</Button>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </div>

                        <div>
                            <Form>
                                <Form.Group as={Row} className="mb-3">
                                    <Col sm={{ span: 12}} style={{display: "flex"}}>
                                        <Button onClick={onBack} style={{backgroundColor: appContext.colors.primaryWhite, color: appContext.colors.primaryBlue}}>Kembali ke Iklan</Button>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </div>
                    </div>:<></>
                    
                }
            </div>
        </>
    )
}

export default InvoiceMessagePage;