import React, { createContext, useState, useEffect } from "react";
import useCheckMobileScreen from "./ResponsiveStyleCheck";

const AppContext = createContext();

const AppContextProvider = (props) => {
    const twindow = typeof window !== 'undefined' ? window : {};

    const mobileMode = useCheckMobileScreen();
    const [ isDarkMode, setIsDarkMode ] = useState(false);
    const multiModeStyle = {
        mainTextColor: isDarkMode ? "white" : "black",
        secondaryTextColor: isDarkMode ? "#B0B0B0" : "#7B7B7B",
        cardBackgroundColor: isDarkMode ? "#3E3E3E" : "white"
    }

    const colors = {
        primaryBlue: "#2E80EB",
        primaryWhite: "#FFFFFF",
        primaryBlack: "#010101",
        primaryGrey: "#959595",
        primaryRed: "#fc0303"
    }

    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
    }

    const progressBar = ({progress,height}) => {
     
      const Parentdiv = {
          height: height,
          width: '300px',
          backgroundColor: 'whitesmoke',
          borderRadius: 40,
        }
        
        const Childdiv = {
          height: '100%',
          width: `${progress}%`,
          backgroundColor: colors.primaryBlue,
          borderRadius:40,
          textAlign: 'right'
        }
        
        const progresstext = {
          padding: 10,
          color: colors.primaryWhite,
          fontWeight: 500
        }
          
      return (
      <div style={Parentdiv}>
        <div style={Childdiv}>
          <span style={progresstext}>{`${progress}%`}</span>
        </div>
      </div>
      )
  }

    const tosStyles = {
        tosContentTitleStyle: {
          fontWeight: "700",
          marginTop: "50px",
          marginBottom: "50px",
          color: colors.primaryBlack
        },
    
        tosContentSubtitleStyle: {
          fontWeight: "500",
          marginTop: "20px",
          marginBottom: "20px",
          color: colors.primaryBlack
        },
    
        tosContentDescriptionStyle: {
          fontWeight: "400",
          lineHeight: "150%",
          color: colors.primaryBlack
        },
    
        tosContentLastChangedStyle: {
          fontWeight: "700",
          color: colors.primaryBlack
        },
    
        tosContentBoldStyle: {
          color: colors.primaryBlack
        },
    
        tosListBulletStyle: {
          color: colors.primaryBlack
        }
      }

    return (
        <AppContext.Provider
        value={{
            mobileMode,
            isDarkMode,
            setIsDarkMode,
            toggleDarkMode,
            multiModeStyle,
            colors,
            tosStyles,
            progressBar
        }}
        >
        {props.children}
        </AppContext.Provider>
    );
}
export { AppContext, AppContextProvider };