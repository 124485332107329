import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //.use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'id',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          // here we will place our translations...
          navigationBar: {
            home: "Home",
            about: "About",
            tos: "Terms of Service",
            help: "Help",
            contact: "Contact Us",
            news: "News",
            freeDownload: "Download Woilo",
            switchLanguage: "Gunakan Bahasa Indonesia",
            tryWoiloNow: "Try Woilo Now"
          },

          landingPage: {
            headlineSection: {
              title: "Hello to you 👋",
              subtitle: "Still not confident about uploading something on social media? Afraid of being bullied? Well, WoiLo is the right platform for you! #ExpressYourself and #NoInsecurity",
              downloadNow: "Download Now",
              review: "Reviews"
            },

            advantageSection: {
              title: "Why Should You Download Woilo?",
              cards: {
                title1: "Made in Indonesia",
                subtitle1: "Who would have thought, WoiLo is the #1 social media platform in Indonesia with a mission to give a platform to freely express yourself. #LocalPride",
                title2: "Privacy Guaranteed",
                subtitle2: "No need to be afraid of your data being leaked everywhere. Your privacy is our main focus. #SafeWithWoilo",
                title3: "Becoming a Content Creator",
                subtitle3: "You don't need to have a lot of followers to be a Content Creator. #EveryoneCanCreate"
              }
            },

            nftSection: {
              title: "WoiLo NFT Digital Asset 😎 ",
              subtitle: "WoiLo released a new feature, WoiLo NFT, where users can convert their posts into NFTs that can be traded.",
              learnMore: "Find Out More"
            },

            popularitySection: {
              title: "Become the Top #1 in Woilo ",
              subtitle: "#AppreciationWithoutDiscrimination for the creators at WoiLo, it's easy, just #BeProductive and work consistently according to your interests and talents!",
              learnMore: "Find Out More"
            },

            featureSection: {
              title: "Woilo Features 😍",
              subtitle: "Looking for entertainment from videos & photos at the same time? Want to make contents but unsure about the topics? Want to chat but don't want your identity to be known? Everything's possible! #EverythingsHere at WoiLo!"
            },

            ourContentCreatorSection: {
              title: "Our Content Creators",
              subtitle: "Curious about the face of the #PopularCreators on WoiLo? Want to get to know Woilo creators? Check out their account now on WoiLo!",
              follower: "Followers"
            },

            callingContentCreatorSection: {
              title: "Calling All Content Creators",
              subtitle: "Want to be a profitable content creator? Want to be a content creator with super friendly netizens? Earn up to 2 Million Rupiah, by registering & becoming a Content Creator on WoiLo!",
              learnMore: "Let's Join!"
            },

            testimonySection: {
              title: "Hear what they say about Woilo ",
              subtitle: "They are active & loyal users since 2019 who have expresses themselves and felt the fun on making friends on WoiLo",
              cards: {
                content1: "WoiLo is a combination of all social media into one, it's not complicated to use it. You can upload anything you want, and most of all the netizens are very friendly!",
                content2: "Really cool! Just drinking coffee while scrolling looking for entertainment, you get a bonus every day. It's really cool, you can add some pocket money :D",
                content3: "I've been using WoiLo for 2 years, I'm very comfortable because I can express myself freely, I'm not afraid of being bullied, there's no such thing as insecurity in my life anymore.",
              }
            },

            contactSection: {
              title: "Let's Help WoiLo to grow even more",
              subtitle: "Ask us anything & give us some feedbacks here."
            }
          },

          aboutPage: {
            missionSection: {
              title: "Our Mission ",
              subtitle: "WoiLo has a dream to create a creative youth population who are not afraid to express themselves on social media. In addition, the presence of WoiLo can give people a sense of pride that Indonesia has a social media that is as good as other applications.",
              localPride: "#LocalPride"
            },

            timelineSection: {
              title: "Let's see our journey ",
              subtitle: "Let's walk down the memory lane and see how WoiLo was created until it managed to survive until now.",
              timelines: {
                content2019: "Before it's named WoiLo, right on April 1, 2019 we released it under the name 'Sestyc'. Sestyc provides users a platform to connect & find new friends. That year we are starting to market our products widely.",
                content2020: "2020 is the year where we attracted 1 million users throughout Indonesia. On this occasion, we continue to improve the performance of the application to be useful & needed by users.",
                content2021: "Exactly on July 18, 2021, we changed our name from Sestyc to WoiLo. The arrival of WoiLo reflects young people in Indonesia who love to chat without being awkward. In 2021, WoiLo continued to innovate and be different, one of them is users can browse social media and earn money.",
                content2022: "The rising interest of young people aspiring to become a Content Creators, WoiLo is here as a full appreciation for them by increasing creators' special features, one of which is Popularity "
              }
            },

            teamSection: {
              title: "Let's Get To Know Our Team ",
              subtitle: "Behind the scenes of WoiLo, there are some great people. They are youths from Surabaya who have a strong determination to contribute to Indonesia, especially in the information technology field."
            }
          },

          termsOfServicePage: {
            lastChanged: "Last updated : Nov 22, 2022",
            changesDisclaimer: {
              title: "Changes to this Policy",
              content: {
                p1: "We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.",
                p2: "We will let You know via a prominent notice on Our Service, prior to the change becoming effective and update the \"Last updated\" date at the top of this Privacy Policy.",
                p3: "You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page."
              }
            },

            dataPolicy: {
              title: "WoiLo Data Policy",
              description: {
                part1: "This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.",
                part2: "We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy."
              },
              title2: "Interpretation and Definitions",
              subtitle1: "Interpretation",
              subtitle1Description: "The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.",
              subtitle2: "Definitions",
              subtitle2Description: "For the purposes of this Privacy Policy:",
              subtitle2TermList: {
                li1: {
                  title: "Account",
                  content: " means a unique account created for You to access our Service or parts of our Service."
                },
                li2: {
                  title: "Affiliate",
                  content: " berarti entitas yang mengendalikan, dikendalikan oleh, atau berada di bawah kendali bersama dengan suatu pihak, di mana \"mengendalikan\" berarti kepemilikan 50% atau lebih dari saham, kepentingan ekuitas, atau sekuritas lain yang berhak memberikan suara untuk pemilihan direktur atau otoritas pengelola lainnya."
                },
                li3: {
                  title: "Application",
                  content: " means an entity that controls, is controlled by or is under common control with a party, where \"control\" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority."
                },
                li4: {
                  title: "Company",
                  content:" (referred to as either \"the Company\", \"We\", \"Us\" or \"Our\" in this Agreement) refers to WoiLo, PT Karya Digital Indo"
                },
                li5: {
                  title: "Country",
                  content: " refers to: Indonesia"
                },
                li6: {
                  title: "Device",
                  content: " means any device that can access the Service such as a computer, a cellphone or a digital tablet."
                },
                li7: {
                  title: "Personal Data",
                  content: " is any information that relates to an identified or identifiable individual."
                },
                li8: {
                  title: "Service",
                  content: " refers to the Application."
                },
                li9: {
                  title: "Service Provider",
                  content: " means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used."
                },
                li10: {
                  title: "Usage Data",
                  content: " refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit)."
                },
                li11: {
                  title: "You",
                  content: " means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable."
                },
              },
              title3: "Collecting and Using Your Personal Data",
              subtitle3: "Types of Data Collected",
              subtitle4: "Personal Data",
              subtitle4Description: "While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:",
              subtitle4TermList: {
                li1: "Email Address",
                li2: "Usage Data"
              },
              subtitle5: "Usage Data",
              subtitle5Description: {
                part1: "Usage Data is collected automatically when using the Service.",
                part2: "Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.",
                part3: "When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.",
                part4: "We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device."
              },
              subtitle6: "Information Collected while Using the Application",
              subtitle6Description: "While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:",
              subtitle6TermList: {
                li1: "Pictures and other information from your Device's camera and photo library",
                li2: "Microphone",
                li3: "Wi-Fi connection information",
                li4: "Storage"
              },
              subtitle6Description2: {
                part1: "We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.",
                part2: "You can enable or disable access to this information at any time, through Your Device settings."
              },
              subtitle7: "Use of Your Personal Data",
              subtitle7Description: "The Company may use Personal Data for the following purposes:",
              subtitle7TermList: {
                li1: {
                  title: "To provide and maintain our Service",
                  content: ", including to monitor the usage of our Service."
                },
                li2: {
                  title: "To manage Your Account:",
                  content: " to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user."
                },
                li3: {
                  title: "For the performance of a contract:",
                  content: " the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service."
                },
                li4: {
                  title: "To contact You:",
                  content: " berita, penawaran khusus, dan informasi umum tentang barang, layanan, dan acara lain yang kami tawarkan yang serupa dengan yang telah Anda beli atau tanyakan kecuali Anda telah memilih untuk tidak menerima informasi tersebut."
                },
                li5: {
                  title: "To provide You",
                  content: " with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information."
                },
                li6: {
                  title: "To manage Your requests:",
                  content: " To attend and manage Your requests to Us."
                },
                li7: {
                  title: "For business transfers:",
                  content: " We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred."
                },
                li8: {
                  title: "For other purposes:",
                  content: " We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience."
                },
              },
              subtitle7Description2: "We may share Your personal information in the following situations:",
              subtitle7TermList2: {
                li1: {
                  title: "With Service Providers:",
                  content: " We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You."
                },
                li2: {
                  title: "For business transfers:",
                  content: " We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company."
                },
                li3: {
                  title: "With Affiliates:",
                  content: " We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us."
                },
                li4: {
                  title: "With business partners:",
                  content: " We may share Your information with Our business partners to offer You certain products, services or promotions."
                },
                li5: {
                  title: "With other users:",
                  content: " when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside."
                },
                li6: {
                  title: "With Your consent:",
                  content: " We may disclose Your personal information for any other purpose with Your consent."
                },
              },
              subtitle8: "Optional Data",
              subtitle8Description: {
                part1: "We may collect additional data if You choose to use our services. These data are only collected when You access the corresponding services.",
                part2: "The additional data we will collect when You use our services are:"
              },
              subtitle8TermList: {
                li1: "Information regarding your location if You choose to use our Nearby People service",
                li2: "Formal identification if You choose to apply for a verification request or registering as a Content Creator"
              },
              subtitle8Description2: "To provide the best user experience, we need to process information about you. The information we collect depends on how you are using WoiLo. Below are some of our rights regarding the data/content you uploaded to WoiLo.",
              subtitle8TermList2: {
                li1: {
                  title: "Uploaded contents.",
                  content: " We have the right to alter or delete contents uploaded to WoiLo without informing the uploader if said content violate the WoiLo Usage Guideline."
                },
                li2: {
                  title: "Your Identity.",
                  content: " The identity you enter at WoiLo is only used with the aim of making it easier for you to build social networks at WoiLo such as finding new friends, being able to be found by others, being recognized by your friends and so on. We also need your formal identity if you apply for our Content Creator program to verify that your identity is authentic and real."
                },
                li3: {
                  title: "Your Location.",
                  content: " Your location will be recorded if you use the Nearby People feature on WoiLo and will be used to approximate the distance between you and other users in the area."
                },
                li4: {
                  title: "Your Usage.",
                  content: " Your engagements to contents and other users will be processed to give you a more personalized user experience."
                },
                li5: {
                  title: "Account Blocking.",
                  content: " We have the right to block and delete your account if we receive reports stating that the account you use has violated the policies we made or the applicable law in your country."
                }
              },
              subtitle9: "Retention of Your Personal Data",
              subtitle9Description: {
                part1: "The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.",
                part2: "The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods."
              },
              subtitle10: "Transfer of Your Personal Data",
              subtitle10Description: {
                part1: "Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.",
                part2: "Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.",
                part3: "The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information."
              },
              title4: "Disclosure of Your Personal Data",
              subtitle11: "Business Transactions",
              subtitle11Description: "If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.",
              subtitle12: "Law enforcement",
              subtitle12Description: "Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).",
              subtitle13: "Other legal requirements",
              subtitle13Description: "The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:",
              subtitle13TermList: {
                li1: "Comply with a legal obligation",
                li2: "Protect and defend the rights or property of the Company",
                li3: "Prevent or investigate possible wrongdoing in connection with the Service",
                li4: "Protect the personal safety of Users of the Service or the public",
                li5: "Protect against legal liability"
              },
              title5: "Security of Your Personal Data",
              title5Description: "The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.",
              subtitle14: "Children's Privacy",
              subtitle14Description: {
                part1: "Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.",
                part2: "If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information."
              },
              subtitle15: "Links to Other Websites",
              subtitle15Description: {
                part1: "Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.",
                part2: "We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services."
              },
            },

            usageGuidelines: {
              title: "WoiLo Usage Guidelines",
              description: "We want our services to be open and can be used by anyone. With that said, we also want our services to be safe, secure, and in accordance with existing legal regulations. Therefore, we ask that you comply with a number of restrictions so that you can become part of WoiLo.",
              termList: {
                li1: "You must be at least 12 years of age to join WoiLo.",
                li2: "You are not allowed to upload contents containing pornography, violence in any form, defamation, racism, terrorism, and/or other negative content.",
                li3: "You are not allowed to provide or sell services related to prostitution, and/or adult products in any form.",
                li4: "You are not allowed to impersonate others or provide inaccurate information (hoaxes).",
                li5: "You are not allowed to sell a WoiLo account (your account or someone else's).",
                li6: "You are not allowed to do anything that could disrupt or damage the operation of the service as it should.",
                li7: "You are not allowed to do things that offends or disturb the comfort of other users.",
              },
              warning: "If you violate the conditions stated above, the penalty that we will give ranges from the removal of the content that you uploaded to the blocking of your account."
            },

            privateAccount: {
              title: "Private Account",
              subtitle: "WoiLo provides the \"Private Account\" feature as a form of privacy provided to users. Here are some things that will happen if you make your account a Private Account:",
              termList: {
                li1: "Private accounts will be hidden from the public.",
                li2: "Only people who have become followers or people who have been approved by you can see your profile.",
                li3: "People who have not become your followers or have not yet approved will not be added to your profile visit list when visiting your profile."
              }
            },

            verifiedAccount: {
              title: "Verified Account Terms",
              description: "We consider several factors to determine whether your account meets our requirements and is eligible for verification. Some of the requirements we will review is as follows:",
              termList: {
                li1: "You can bring a positive impact on the WoiLo user community.",
                li2: "You do not assume someone else's identity as your personal identity.",
                li3: "You have never violated WoiLo's guidelines.",
                li4: "Your account represents a well-known, sought-after person, brand or entity.",
                li5: "Your account has a profile picture, bio, and at least 1 post.",
                li6: "Your account must be active for at least 1 month at WoiLo.",
                li7: "Your identity must be clear, authentic, and real.",
                li8: "You are a public figure (celebrity, artist, etc.) who has a positive impact on many people.",
              },
              confirmation: "If you have met the requirements that we listed, the WoiLo moderator team will consider further before carrying out the account verification process."
            },

            woiloCoin: {
              title: "Terms of Woilo Coin",
              subtitle: "We provide WoiLo Coins to users to make purchases within the WoiLo application with the following conditions:",
              termList: {
                li1: "WoiLo coins can only be used in the WoiLo application.",
                li2: "WoiLo coins cannot be cashed in.",
                li3: "All products that have been purchased with WoiLo Coins cannot be returned or exchanged with other products.",
                li4: "All activities done to receive WoiLo Coins for free apart from our intended means will be acted on in the form of blocking your account."
              }
            },

            woiloWallet: {
              title: "Lo-Wallet",
              description: "WoiLo provides an opportunity for users to earn bonuses based on user interactions with content on WoiLo. The amount of income earned will be calculated using an algorithm that has been developed by WoiLo.",
              subtitle: "Terms of Use",
              subtitleDescription: "Each user is required to agree to the following Lo-Wallet Terms of Use to participate in this program.",
              termList1: {
                li1: "Woilo does not promise an exact amount of revenue that users will earn. The amount of revenue of users may vary from day to day.",
                li2: "We can deduct or remove the funds that have been accumulated in your Woilo account if any activities connected to Your Account are detected to be fraudulent, damaging, and/or harming to WoiLo in any way, shape, or form.",
                li3: "Funds that have been accumulated in your Woilo account can be deducted or removed if no activity is detected on your Woilo account for the last 14 days."
              },
              warning: "Any violation committed by users that can or will harm WoiLo or other users will result in account blocking and the user will not receive the accumulated income.",
              subtitle2: "Withdrawing Lo-Wallet Funds",
              termList2: {
                li1: "Withdrawal of Lo-Wallet funds can only be done using payment methods provided by Woilo including Local Banks in Indonesia and some Digital Wallets in Indonesia.",
                li2: "Withdrawal of Lo-Wallet funds will only be served on weekdays (Monday to Friday) and working hours (09.00 to 17.00 Western Indonesian Time). If you request a withdrawal outside of the specified time frame, your withdrawal request will be processed on the next business day and hour."
              }
            },

            popularityEvent: {
              title: "Popularity Event",
              description: "WoiLo organizes this Popularity Events to give users the opportunity to win prizes by accumulating popularity and getting into the highest rankings. In order to maintain fairness and comfort for participating users, we provide some rules and conditions that must be followed.",
              subtitle: "Popularity Event Terms",
              subtitleDescription: "Each user is required to agree to the following Popularity Event Terms to participate in this program.",
              termList: {
                li1: "The uploaded content must be your own creation and not the work of others.",
                li2: "It is forbidden to use fake accounts to increase engagement and increase Popularity points.",
                li3: "It is forbidden to disturb the convenience of other users for the purpose of increasing post engagement and increasing Popularity points.",
                li4: "The moderator team from WoiLo will always check and survey the content and interactions of each user to prevent cheating.",
                li5: "Users who participate in Popularity Events are not allowed to violate the WoiLo Terms of Use in any shape or form."
              },
              warning: "Any violation committed by users that can or will harm WoiLo or other users will result in disqualification from Popularity Events to blocking depending on the level of damage."
            },

            nftTerms: {
              title: "NFT",
              description: "We facilitate the creations, sales, and purchases of NFT within Woilo. Some terms regarding the NFT features in Woilo are as follows:",
              termList: {
                li1: "You can generate NFTs from posts you upload on Woilo.",
                li2: "You can sell the NFTs you created on Woilo.",
                li3: "You can buy NFTs that other users have created on Woilo.",
                li4: "The buying and selling of NFTs on Woilo uses an auction system.",
                li5: "The buying and selling of NFTs on Woilo will use the Lo-Wallet balance.",
                li6: "You can sell your NFTs on the global NFT marketplace ( Opensea ) through Woilo if you meet the requirements.",
                li7: "Every NFT transaction within Woilo will be subject to VAT of 2% of the transaction value and users who have sold an NFT are required to pay income tax in accordance with applicable law.",
                li8: "All kinds of activities that contain elements of fraud, damage, and/or harm to other users or the Woilo system will be followed up with sanctions ranging from account blocking to being processed legally depending on the severity of the violation."
              },
              taxDocument: "Link to VAT and Income tax law on crypto transactions",
              description2: "The user's NFT creation will go through a vetting process by the Woilo moderator team. Some of the conditions that must be met are as follows:",
              termList2: {
                li1: "Contents that are to be created as NFTs must be your own work and must not plagiarize the work of others.",
                li2: "Contents that are to be created as NFTs are not allowed to contain explicit elements, pornography, violence in any form, defamation, racism, terrorism, and/or other negative content.",
                li3: "Users who wish to create NFTs are not allowed to violate the WoiLo Terms of Use in any way.",
              },
              confirmation: "If all the requirements listed above have been met, the WoiLo moderator team will consider further before continuing the NFT creation process."
            }
          },

          helpPage: {
            title: "Need Help?",
            subtitle: "Don't hesitate to contact us here!",
            infocard: {
              title1: "For Business Inquiries:",
              title2: "Give Feedback and Suggestions:"
            },
            faqTitle: "Frequently Asked Questions",
            faq: {
              question1: "How can I participate in popularity events?",
              answer1: "You can take part in the monthly event in an easy way, just by sharing your own creations and inviting your audience to interact. This event can be attended by everyone, without having a lot of followers. Come on and get a prize each month for a total of 2 Million Rupiah!",
              question2: "Is my personal data secure?",
              answer2: "Yes, at WoiLo you don't have to worry about your data being leaked. WoiLo can ensure that all user data is safe and their privacy is guaranteed. 1 Million WoiLo users have proven it~",
              question3: "How can I withdraw my balance?",
              answer3: "You can withdraw your balance in the Lo-Wallet menu in the balance withdrawal section and then you can choose the method of withdrawing funds via E-Wallet / Bank. You can only withdraw funds with a minimum balance of IDR 10,000.",
              question4: "How to create quality content and attract visitors on WoiLo?",
              answer4: "It's really easy, you have to create original content or create your own. WoiLo does not recommend content that is taken from other platforms. So that your posts are busy, invite your audience to interact by liking & commenting on your posts. Want to know more tips? Join WoiLo now and we give exclusive tips for you!",
            }
          },

          contactPage: {
            contact_us: "Contact Us",
            email: "Email",
            full_name: "Full Name",
            inquiry: "Inquiry",
            entity: "Entity",
            message: "Message",
            company_name: "Institution / Company Name",
            company_website: "Institution / Company Website",
            term_check: "By filling up this form, I agree that this data is true and authentic. I am ready to be processed legally if it is proven that the data provided is false and/or not authentic.",
            inquiry_1: "Partnership",
            inquiry_2: "Investment",
            inquiry_3: "Other inquiry",
            entity_1: "Individual",
            entity_2: "Institution / Company / Organization",
            send: "Send",
            error: "Please complete the form",
            success: "Your message has been delivered, our team will reach you out within 7 days.",
            failed: "Ups, something went wrong. Please try again later"
          },
          
          blogPage: {
            description: "Want some tips on how to be a content creator for free? Feel free to read these articles by Woilo",
            search: "Search",
            searchTooltip: "Look for tips",
            exploreTags: "Explore other tags",
            tags: {
              latest: "Latest"
            }
          },

          footerSection: {
            menu: "Menu",
            home: "Home",
            contact: "Contact Us",
            download: "Download Woilo",
            product: "Product",
            woilo_ads: "Woilo Ads",
            woilo_creator: "Woilo Creator",
            information: "Information",
            term_of_use: "Term of Use",
            privacy_policy: "Privacy Policy"
          }
        }
      },
      id: {
          translation: {
            navigationBar: {
              home: "Beranda",
              about: "Tentang",
              tos: "Kebijakan Pengguna",
              help: "Bantuan",
              contact: "Hubungi Kami",
              news: "Berita",
              freeDownload: "Unduh Woilo",
              switchLanguage: "Switch to English",
              tryWoiloNow: "Coba Woilo Sekarang"
            },

            landingPage: {
              headlineSection: {
                title: "Halo Kamu, yang baca ini 👋",
                subtitle: "Masih gak pede unggah sesuatu di sosial media? Takut dibully? Nah, WoiLo platform yang pas buat kamu! #UngkapRasamu dan  #BebasInsecure",
                downloadNow: "Download Sekarang",
                review: "Ulasan"
              },

              advantageSection: {
                title: "Kenapa Harus Download Woilo ?",
                card: {
                  title1: "Karya Anak Bangsa",
                  subtitle1: "Siapa sangka, WoiLo adalah platform sosial media no #1 di Indonesia yang memiliki misi untuk berani berekspresi. #BanggaProdukIndonesia",
                  title2: "Jaminan Privasi Terjaga",
                  subtitle2: "Gak perlu takut data kamu tersebar dimanapun. Privasimu adalah fokus utama kami. #AmanBersamaWoiLo",
                  title3: "Menjadi Konten Kreator",
                  subtitle3: "Gak perlu punya banyak followers untuk bisa jadi Konten Kreator. #SemuaBisaJadiKreator"
                }
              },

              nftSection: {
                title: "WoiLo NFT Asset Digital 😎 ",
                subtitle: "WoiLo mengeluarkan fitur baru yaitu WoiLo NFT dimana para pengguna dapat mengubah konten yang mereka upload menjadi NFT yang bisa diperjualbelikan.",
                learnMore: "Cari Tahu Penggunaannya"
              },

              popularitySection: {
                title: "Jadilah Top 1 di Woilo 😎",
                subtitle: "#ApresiasiTanpaPandangBulu untuk para kreator di WoiLo caranya gampang, cukup #JadilahProduktif dan konsisten berkarya sesuai minat bakatmu!",
                learnMore: "Cari Tahu Penggunaannya"
              },

              featureSection: {
                title: "Fitur Unggulan Woilo 😍",
                subtitle: "Mau cari hiburan dari status, video & foto sekaligus? Mau bikin konten tapi bingung bikin topiknya? Mau chattingan tapi gak mau ketahuan? Bisa banget! #SemuaAda di WoiLo!"
              },

              ourContentCreatorSection: {
                title: "Konten Kreator Kami ☺️",
                subtitle: "Penasaran wajah #KreatorPopuler di WoiLo? Mau kenalan dengan para kreator woilo secara langsung? Kunjungi akun mereka sekarang di WoiLo!",
                follower: "Pengikut"
              },

              callingContentCreatorSection: {
                title: "Calling All Content Creators",
                subtitle: "Mau jadi konten kreator berpenghasilan?  Mau jadi konten kreator plus netizennya super ramah? Dapatkan penghasilan 2 Juta Rupiah, dengan mendaftar & jadi Konten Kreator di WoiLo!",
                learnMore: "Yuk Ikutan!"
              },

              testimonySection: {
                title: "Dengar apa kata mereka tentang Woilo 🤓",
                subtitle: "Mereka adalah pengguna aktif & setia sejak 2019 yang sudah berani berekspresi dan merasakan enaknya main sosmed di WoiLo",
                card: {
                  content1: "WoiLo adalah perpaduan semua sosmed jadi satu, gak ribet pakenya. Mau unggah apa aja bisa, dan paling suka banget netizennya ramah sekali!",
                  content2: "Keren banget! Cuma minum kopi sambil scrolling cari hiburan aja dapet bonus setiap hari. Asik banget, bisa nambah uang jajan :D",
                  content3: "Udah 2 tahun pake WoiLo, aku nyaman banget karena bisa bebas berekspresi, gak takut dibully, udah gak ada lagi tuh yang namanya insecure di hidupku",
                }
              },

              contactSection: {
                title: "Yuk Bantu WoiLo untuk dapat berkembang lebih lagi",
                subtitle: "Tanya apapun & beri masukan untuk kami disini."
              }
            },

            aboutPage: {
              missionSection: {
                title: "Misi Kami 🙌",
                subtitle: "WoiLo memiliki mimpi mencetak anak-anak muda yang kreatif dan tidak takut berekspresi di Sosial Media. Selain itu, dengan kehadiran WoiLo mampu memberi rasa bangga terhadap masyarakat bahwa Indonesia memiliki Sosial Media yang tidak kalah hebat dari aplikasi lainnya.",
                localPride: "#BanggaProdukLokal"
              },

              timelineSection: {
                title: "Kepoin yuk Perjalanan kami 👇",
                subtitle: "Mari kita kilas balik bagaimana WoiLo berdiri sampai berhasil bertahan hingga saat ini.",
                timelines: {
                  content2019: "Sebelum WoiLo hadir,  tepat pada 1 April 2019 kami merilis dengan nama ‘Sestyc’. Sestyc masih menyuguhkan penggunanya untuk terhubung & menemukan teman baru. Tahun ini memulai untuk memasarkan produk secara luas.",
                  content2020: "2020 adalah tahun dimana kami bisa menggaet 1 juta pengguna di seluruh Indonesia. Di kesempatan inilah, kami terus meningkatkan performa aplikasi agar disukai & dibutuhkan oleh pengguna.",
                  content2021: "Tepat 18 Juli 2021, kami mengganti nama dari Sestyc menjadi WoiLo. Kehadiran WoiLo mencerminkan anak-anak muda di Indonesia yang senang bercengkrama tanpa canggung. Pada 2021, WoiLo berinovasi untuk menjadi yang paling beda, salah satunya pengguna dapat bermain sosial media sekaligus mendapatkan uang.",
                  content2022: "Tingginya minat anak-anak muda untuk bercita-cita menjadi Konten Kreator, WoiLo hadir sebagai apresiasi penuh untuk mereka dengan meningkatkan fitur khusus kreator salah satunya ialah Popularitas  "
                }
              },

              teamSection: {
                title: "Kenalan Yuk Sama Tim Kami 👋",
                subtitle: "Di balik layar WoiLo, ada beberapa orang hebat di belakangnya. Mereka adalah anak-anak muda asal Surabaya yang memiliki tekad kuat untuk berkontribusi pada Indonesia khususnya Teknologi Informasi."
              }
            },

            termsOfServicePage: {
              lastChanged: "Terakhir diperbarui : 22 November 2022",

              changesDisclaimer: {
                title: "Perubahan pada Kebijakan ini",
                content: {
                  p1: "Kami dapat memperbarui Kebijakan Privasi Kami dari waktu ke waktu. Kami akan memberi tahu Anda tentang perubahan apa pun dengan memposting Kebijakan Privasi baru di halaman ini.",
                  p2: "Kami akan memberi tahu Anda melalui pemberitahuan penting di Layanan Kami, sebelum perubahan menjadi efektif dan memperbarui \"Terakhir diperbarui\" tanggal di bagian atas Kebijakan Privasi ini.",
                  p3: "Anda disarankan untuk meninjau Kebijakan Privasi ini secara berkala untuk setiap perubahan. Perubahan pada Kebijakan Privasi ini berlaku efektif saat diposting di halaman ini."
                }
              },

              dataPolicy: {
                title: "Kebijakan Data Woilo",
                description: {
                  part1: "Kebijakan Privasi ini menjelaskan kebijakan dan prosedur kami tentang pengumpulan, penggunaan, dan pengungkapan informasi Anda saat Anda menggunakan Layanan dan memberi tahu Anda tentang hak privasi Anda dan bagaimana hukum melindungi Anda.",
                  part2: "Kami menggunakan data pribadi Anda untuk menyediakan dan meningkatkan Layanan. Dengan menggunakan Layanan, Anda menyetujui pengumpulan dan penggunaan informasi sesuai dengan Kebijakan Privasi ini."
                },
                title2: "Interpretasi dan Definisi",
                subtitle1: "Interpretasi",
                subtitle1Description: "Kata-kata yang huruf awalnya dikapitalisasi memiliki arti yang ditentukan dalam kondisi berikut. Definisi berikut akan memiliki arti yang sama terlepas dari apakah mereka muncul dalam bentuk tunggal atau jamak.",
                subtitle2: "Definisi",
                subtitle2Description: "Untuk tujuan Kebijakan Privasi ini :",
                subtitle2TermList: {
                  li1: {
                    title: "Akun",
                    content: " berarti akun unik yang Anda buat untuk mengakses Layanan kami atau bagian dari Layanan kami."
                  },
                  li2: {
                    title: "Afiliasi",
                    content: " berarti entitas yang mengendalikan, dikendalikan oleh, atau berada di bawah kendali bersama dengan suatu pihak, di mana \"mengendalikan\" berarti kepemilikan 50% atau lebih dari saham, kepentingan ekuitas, atau sekuritas lain yang berhak memberikan suara untuk pemilihan direktur atau otoritas pengelola lainnya."
                  },
                  li3: {
                    title: "Aplikasi",
                    content: " berarti program perangkat lunak yang disediakan oleh Kami yang Anda unduh pada perangkat elektronik apa pun, bernama WoiLo"
                  },
                  li4: {
                    title: "Perusahaan",
                    content:" (disebut sebagai \"Perusahaan\" atau \"Kami\" dalam Perjanjian ini) mengacu pada WoiLo, PT Karya Digital Indo"
                  },
                  li5: {
                    title: "Negara",
                    content: " mengacu pada: Indonesia"
                  },
                  li6: {
                    title: "Perangkat",
                    content: " berarti perangkat apa pun yang dapat mengakses Layanan seperti komputer, ponsel, atau tablet digital."
                  },
                  li7: {
                    title: "Data Pribadi",
                    content: " adalah informasi apa pun yang berkaitan dengan individu yang teridentifikasi atau dapat diidentifikasi."
                  },
                  li8: {
                    title: "Layanan",
                    content: " mengacu pada Aplikasi."
                  },
                  li9: {
                    title: "Penyedia Layanan",
                    content: " berarti setiap orang atau badan hukum yang memproses data atas nama Perusahaan. Ini mengacu pada perusahaan atau individu pihak ketiga yang dipekerjakan oleh Perusahaan untuk memfasilitasi Layanan, untuk menyediakan Layanan atas nama Perusahaan, untuk melakukan layanan yang terkait dengan Layanan Kami atau untuk membantu Perusahaan dalam menganalisa bagaimana Layanan digunakan."
                  },
                  li10: {
                    title: "Data Pengunaan",
                    content: " mengacu pada data yang dikumpulkan secara otomatis, baik yang dihasilkan oleh penggunaan Layanan atau dari infrastruktur Layanan itu sendiri (misalnya, durasi kunjungan halaman)."
                  },
                  li11: {
                    title: "Anda",
                    content: " berarti individu yang mengakses atau menggunakan Layanan, atau perusahaan, atau badan hukum lainnya atas nama individu yang mengakses atau menggunakan Layanan, sebagaimana berlaku."
                  },
                },
                title3: "Mengumpulkan dan Menggunakan Data Pribadi Anda",
                subtitle3: "Jenis Data yang Dikumpulkan",
                subtitle4: "Data Pribadi",
                subtitle4Description: "Saat menggunakan Layanan Kami, Kami dapat meminta Anda untuk memberikan Kami informasi pengenal pribadi tertentu yang dapat digunakan untuk menghubungi atau mengidentifikasi Anda. Informasi pengenal pribadi dapat mencakup, tetapi tidak terbatas pada:",
                subtitle4TermList: {
                  li1: "Alamat email",
                  li2: "Data Penggunaan"
                },
                subtitle5: "Data Penggunaan",
                subtitle5Description: {
                  part1: "Data Penggunaan dikumpulkan secara otomatis saat menggunakan Layanan.",
                  part2: "Data Penggunaan dapat mencakup informasi seperti alamat Protokol Internet Perangkat Anda (misalnya alamat IP), jenis browser, versi browser, halaman Layanan kami yang Anda kunjungi, waktu dan tanggal kunjungan Anda, waktu yang dihabiskan di halaman tersebut , pengidentifikasi perangkat unik, dan data diagnostik lainnya.",
                  part3: "Saat Anda mengakses Layanan dengan atau melalui perangkat seluler, Kami dapat mengumpulkan informasi tertentu secara otomatis, termasuk, namun tidak terbatas pada, jenis perangkat seluler yang Anda gunakan, ID unik perangkat seluler Anda, alamat IP perangkat seluler Anda , sistem operasi seluler Anda, jenis peramban Internet seluler yang Anda gunakan, pengidentifikasi perangkat unik, dan data diagnostik lainnya.",
                  part4: "Kami juga dapat mengumpulkan informasi yang dikirimkan browser Anda setiap kali Anda mengunjungi Layanan kami atau saat Anda mengakses Layanan dengan atau melalui perangkat seluler."
                },
                subtitle6: "Informasi yang Dikumpulkan saat Menggunakan Aplikasi",
                subtitle6Description: "Saat menggunakan Aplikasi Kami, untuk menyediakan fitur Aplikasi Kami, Kami dapat mengumpulkan, dengan izin Anda sebelumnya:",
                subtitle6TermList: {
                  li1: "Gambar dan informasi lain dari kamera dan perpustakaan foto Perangkat Anda",
                  li2: "Mikrofon",
                  li3: "Informasi koneksi Wi-Fi",
                  li4: "Penyimpanan"
                },
                subtitle6Description2: {
                  part1: "Kami menggunakan informasi ini untuk menyediakan fitur Layanan Kami, untuk meningkatkan dan menyesuaikan Layanan Kami. Informasi tersebut dapat diunggah ke server Perusahaan dan/atau server Penyedia Layanan atau mungkin hanya disimpan di perangkat Anda.",
                  part2: "Anda dapat mengaktifkan atau menonaktifkan akses ke informasi ini kapan saja, melalui setelan Perangkat Anda."
                },
                subtitle7: "Penggunaan Data Pribadi Anda",
                subtitle7Description: "Perusahaan dapat menggunakan Data Pribadi untuk tujuan berikut:",
                subtitle7TermList: {
                  li1: {
                    title: "Untuk menyediakan dan memelihara Layanan kami",
                    content: ", termasuk untuk memantau penggunaan Layanan kami."
                  },
                  li2: {
                    title: "Untuk mengelola Akun Anda:",
                    content: " untuk mengelola pendaftaran Anda sebagai pengguna Layanan. Data Pribadi yang Anda berikan dapat memberi Anda akses ke berbagai fungsi Layanan yang tersedia untuk Anda sebagai pengguna terdaftar."
                  },
                  li3: {
                    title: "Untuk pelaksanaan kontrak:",
                    content: " pengembangan, kepatuhan, dan pelaksanaan kontrak pembelian untuk produk, item, atau layanan yang telah Anda beli atau kontrak lainnya dengan Kami melalui Layanan."
                  },
                  li4: {
                    title: "Untuk menghubungi Anda:",
                    content: " Untuk menghubungi Anda melalui email, panggilan telepon, SMS, atau bentuk komunikasi elektronik lain yang setara, seperti pemberitahuan push aplikasi seluler mengenai pembaruan atau komunikasi informatif yang terkait dengan fungsi, produk atau layanan yang dikontrak, termasuk pembaruan keamanan, bila perlu atau wajar untuk penerapannya."
                  },
                  li5: {
                    title: "Untuk memberi Anda",
                    content: " berita, penawaran khusus, dan informasi umum tentang barang, layanan, dan acara lain yang kami tawarkan yang serupa dengan yang telah Anda beli atau tanyakan kecuali Anda telah memilih untuk tidak menerima informasi tersebut."
                  },
                  li6: {
                    title: "Untuk mengelola permintaan Anda:",
                    content: " Untuk memproses dan mengelola permintaan Anda kepada Kami."
                  },
                  li7: {
                    title: "Untuk transfer bisnis:",
                    content: " Kami dapat menggunakan informasi Anda untuk mengevaluasi atau melakukan merger, divestasi, restrukturisasi, reorganisasi, pembubaran, atau penjualan atau transfer lainnya dari sebagian atau seluruh aset Kami, baik sebagai kelangsungan usaha atau sebagai bagian dari kebangkrutan, likuidasi, atau proses serupa, di mana Data Pribadi yang Kami simpan tentang pengguna Layanan kami termasuk di antara aset yang dialihkan."
                  },
                  li8: {
                    title: "Untuk tujuan lain:",
                    content: " Kami dapat menggunakan informasi Anda untuk tujuan lain, seperti analisis data, mengidentifikasi tren penggunaan, menentukan efektivitas kampanye promosi kami, dan untuk mengevaluasi serta meningkatkan Layanan, produk, layanan kami , pemasaran, dan pengalaman Anda."
                  },
                },
                subtitle7Description2: "Kami dapat membagikan informasi pribadi Anda dalam situasi berikut:",
                subtitle7TermList2: {
                  li1: {
                    title: "Dengan Penyedia Layanan:",
                    content: " Kami dapat membagikan informasi pribadi Anda dengan Penyedia Layanan untuk memantau dan menganalisis penggunaan Layanan kami, untuk menghubungi Anda."
                  },
                  li2: {
                    title: "Untuk transfer bisnis:",
                    content: " Kami dapat membagikan atau mentransfer informasi pribadi Anda sehubungan dengan, atau selama negosiasi, merger, penjualan aset Perusahaan, pembiayaan, atau akuisisi semua atau sebagian dari bisnis ke perusahaan lain."
                  },
                  li3: {
                    title: "Dengan Afiliasi:",
                    content: " Kami dapat membagikan informasi Anda dengan afiliasi Kami, dalam hal ini kami akan mewajibkan afiliasi tersebut untuk mematuhi Kebijakan Privasi ini. Afiliasi termasuk perusahaan induk Kami dan anak perusahaan lainnya, mitra usaha patungan, atau perusahaan lain yang Kami kendalikan atau yang berada di bawah kendali yang sama dengan Kami."
                  },
                  li4: {
                    title: "Dengan mitra bisnis:",
                    content: " Kami dapat membagikan informasi Anda dengan mitra bisnis Kami untuk menawarkan produk, layanan, atau promosi tertentu kepada Anda."
                  },
                  li5: {
                    title: "Dengan pengguna lain:",
                    content: " ketika Anda berbagi informasi pribadi atau berinteraksi di area publik dengan pengguna lain, informasi tersebut dapat dilihat oleh semua pengguna dan dapat didistribusikan secara publik di luar."
                  },
                  li6: {
                    title: "Dengan persetujuan Anda:",
                    content: " Kami dapat mengungkapkan informasi pribadi Anda untuk tujuan lain apa pun dengan persetujuan Anda."
                  },
                },
                subtitle8: "Data Opsional",
                subtitle8Description: {
                  part1: "Kami dapat mengumpulkan data tambahan jika Anda memilih untuk menggunakan beberapa layanan kami. Data ini hanya dikumpulkan saat Anda mengakses layanan yang sesuai",
                  part2: "Data tambahan yang akan kami kumpulkan saat Anda menggunakan layanan kami adalah:"
                },
                subtitle8TermList: {
                  li1: "Informasi mengenai lokasi Anda jika Anda memilih untuk menggunakan layanan Orang Sekitar kami",
                  li2: "Identifikasi formal jika Anda memilih untuk mengajukan permintaan verifikasi atau mendaftar sebagai Content Creator"
                },
                subtitle8Description2: "Untuk memberikan pengalaman pengguna terbaik, kami perlu memproses informasi tentang Anda. Informasi yang kami kumpulkan tergantung pada bagaimana Anda menggunakan WoiLo. Berikut adalah beberapa hak kami terkait data/konten yang Anda unggah ke WoiLo.",
                subtitle8TermList2: {
                  li1: {
                    title: "Konten yang diunggah.",
                    content: " Kami berhak mengubah atau menghapus konten yang diunggah ke WoiLo tanpa memberi tahu pengunggah jika konten tersebut melanggar Pedoman Penggunaan WoiLo."
                  },
                  li2: {
                    title: "Identitas Anda.",
                    content: " Identitas yang Anda masukkan di WoiLo hanya digunakan dengan tujuan untuk memudahkan Anda dalam membangun jejaring sosial di WoiLo seperti mencari teman baru, dapat ditemukan oleh orang lain , dikenali oleh teman-teman Anda dan sebagainya. Kami juga memerlukan identitas formal Anda jika Anda mendaftar ke program Content Creator kami untuk memverifikasi bahwa identitas Anda asli dan nyata."
                  },
                  li3: {
                    title: "Lokasi Anda.",
                    content: " Lokasi Anda akan direkam jika Anda menggunakan fitur Orang Sekitar di WoiLo dan akan digunakan untuk memperkirakan jarak antara Anda dan pengguna lain di area tersebut."
                  },
                  li4: {
                    title: "Penggunaan Anda.",
                    content: " Interaksi Anda dengan konten dan pengguna lain akan diproses untuk memberi Anda pengalaman pengguna yang lebih dipersonalisasi."
                  },
                  li5: {
                    title: "Pemblokiran Akun.",
                    content: " Kami berhak memblokir dan menghapus akun Anda jika kami menerima laporan yang menyatakan bahwa akun yang Anda gunakan telah melanggar kebijakan yang kami buat atau hukum yang berlaku di negara Anda."
                  }
                },
                subtitle9: "Retensi Data Pribadi Anda",
                subtitle9Description: {
                  part1: "Perusahaan akan menyimpan Data Pribadi Anda hanya selama diperlukan untuk tujuan yang ditetapkan dalam Kebijakan Privasi ini. Kami akan menyimpan dan menggunakan Data Pribadi Anda sejauh yang diperlukan untuk mematuhi kewajiban hukum kami (misalnya, jika kami diharuskan menyimpan data Anda untuk mematuhi hukum yang berlaku), menyelesaikan perselisihan, dan menegakkan perjanjian dan kebijakan hukum kami.",
                  part2: "Perusahaan juga akan menyimpan Data Penggunaan untuk tujuan analisis internal. Penggunaan Data umumnya disimpan untuk jangka waktu yang lebih singkat, kecuali jika data ini digunakan untuk memperkuat keamanan atau untuk meningkatkan fungsionalitas Layanan Kami, atau Kami secara hukum berkewajiban untuk menyimpan data ini untuk jangka waktu yang lebih lama."
                },
                subtitle10: "Transfer Data Pribadi Anda",
                subtitle10Description: {
                  part1: "Informasi Anda, termasuk Data Pribadi, diproses di kantor operasional Perusahaan dan di tempat lain di mana pihak-pihak yang terlibat dalam pemrosesan berada. Ini berarti bahwa informasi ini dapat ditransfer ke — dan disimpan di — komputer yang terletak di luar negara bagian Anda, provinsi, negara atau yurisdiksi pemerintah lainnya di mana undang-undang perlindungan data mungkin berbeda dari yurisdiksi Anda.",
                  part2: "Persetujuan Anda terhadap Kebijakan Privasi ini diikuti dengan penyerahan Anda atas informasi tersebut menunjukkan persetujuan Anda atas transfer tersebut.",
                  part3: "Perusahaan akan mengambil semua langkah yang diperlukan secara wajar untuk memastikan bahwa data Anda diperlakukan dengan aman dan sesuai dengan Kebijakan Privasi ini dan tidak ada transfer Data Pribadi Anda yang akan dilakukan ke organisasi atau negara kecuali ada kontrol yang memadai termasuk keamanan data Anda dan informasi pribadi lainnya."
                },
                title4: "Pengungkapan Data Pribadi Anda",
                subtitle11: "Transaksi Bisnis",
                subtitle11Description: "Jika Perusahaan terlibat dalam merger, akuisisi, atau penjualan aset, Data Pribadi Anda dapat dialihkan. Kami akan memberikan pemberitahuan sebelum Data Pribadi Anda ditransfer dan tunduk pada Kebijakan Privasi yang berbeda.",
                subtitle12: "Penegakan hukum",
                subtitle12Description: "Dalam keadaan tertentu, Perusahaan mungkin diminta untuk mengungkapkan Data Pribadi Anda jika diwajibkan oleh hukum atau sebagai tanggapan atas permintaan yang sah oleh otoritas publik (misalnya pengadilan atau lembaga pemerintah).",
                subtitle13: "Persyaratan hukum lainnya",
                subtitle13Description: "Perusahaan dapat mengungkapkan Data Pribadi Anda dengan itikad baik dengan keyakinan bahwa tindakan tersebut diperlukan untuk:",
                subtitle13TermList: {
                  li1: "Mematuhi kewajiban hukum",
                  li2: "Melindungi dan membela hak atau properti Perusahaan",
                  li3: "Mencegah atau menyelidiki kemungkinan kesalahan sehubungan dengan Layanan",
                  li4: "Melindungi keamanan pribadi Pengguna Layanan atau publik",
                  li5: "Melindungi dari tanggung jawab hukum"
                },
                title5: "Keamanan Data Pribadi Anda",
                title5Description: "Keamanan Data Pribadi Anda penting bagi Kami, tetapi ingat bahwa tidak ada metode transmisi melalui Internet, atau metode penyimpanan elektronik yang 100% aman. Meskipun Kami berusaha untuk menggunakan cara yang dapat diterima secara komersial untuk melindungi Data Pribadi Anda, Kami tidak dapat menjamin keamanan mutlaknya.",
                subtitle14: "Privasi Anak",
                subtitle14Description: {
                  part1: "Layanan kami tidak ditujukan kepada siapa pun yang berusia di bawah 13 tahun. Kami tidak dengan sengaja mengumpulkan informasi pengenal pribadi dari siapa pun yang berusia di bawah 13 tahun. Jika Anda adalah orang tua atau wali dan Anda mengetahui bahwa anak Anda telah memberikan Informasi Pribadi kepada Kami, silahkan hubungi Kami. Jika kita menyadari bahwa Kami telah mengumpulkan Data Pribadi dari siapa pun yang berusia di bawah 13 tahun tanpa verifikasi persetujuan orang tua, Kami mengambil langkah-langkah untuk menghapus informasi tersebut dari server Kami.",
                  part2: "Jika Kami perlu mengandalkan persetujuan sebagai dasar hukum untuk memproses informasi Anda dan negara Anda memerlukan persetujuan dari orang tua, Kami mungkin memerlukan persetujuan orang tua Anda sebelum Kami mengumpulkan dan menggunakan informasi tersebut."
                },
                subtitle15: "Tautan ke Situs Web Lain",
                subtitle15Description: {
                  part1: "Layanan Kami mungkin berisi tautan ke situs web lain yang tidak dioperasikan oleh Kami. Jika Anda mengklik tautan pihak ketiga, Anda akan diarahkan ke situs pihak ketiga tersebut. Kami sangat menyarankan Anda untuk meninjau Kebijakan Privasi setiap situs yang Anda kunjungi.",
                  part2: "Kami tidak memiliki kendali dan tidak bertanggung jawab atas konten, kebijakan privasi, atau praktik situs atau layanan pihak ketiga mana pun."
                },
              },

              usageGuidelines: {
                title: "Pedoman Penggunaan Woilo",
                description: "Kami ingin layanan kami terbuka dan dapat digunakan oleh siapa saja. Karena itu, kami juga ingin layanan kami aman, terjamin, dan sesuai dengan peraturan hukum yang ada. Oleh karena itu, kami meminta Anda untuk mematuhi sejumlah batasan agar Anda dapat menjadi bagian dari WoiLo.",
                termList: {
                  li1: "Anda harus berusia minimal 12 tahun untuk bergabung dengan WoiLo.",
                  li2: "Anda tidak diperbolehkan mengunggah konten yang mengandung pornografi, kekerasan dalam bentuk apapun, fitnah, rasisme, terorisme, dan / atau konten negatif lainnya.",
                  li3: "Anda tidak diperbolehkan menyediakan atau menjual layanan yang berkaitan dengan prostitusi, dan / atau produk dewasa dalam bentuk apapun.",
                  li4: "Anda tidak diperbolehkan menyamar sebagai orang lain atau memberikan informasi yang tidak akurat (hoax).",
                  li5: "Anda tidak diperbolehkan menjual akun WoiLo (akun Anda atau orang lain).",
                  li6: "Anda tidak diperbolehkan melakukan apa pun yang dapat mengganggu atau merusak pengoperasian layanan sebagaimana mestinya.",
                  li7: "Anda tidak diperbolehkan melakukan hal-hal yang menyinggung atau mengganggu kenyamanan pengguna lain.",
                },
                warning: "Jika Anda melanggar ketentuan yang disebutkan di atas, maka penalti yang akan kami berikan mulai dari penghapusan konten yang Anda unggah hingga pemblokiran akun Anda."
              },

              privateAccount: {
                title: "Akun Privat",
                subtitle: "WoiLo menyediakan fitur \"Akun Privat\" sebagai bentuk privasi yang diberikan kepada pengguna. Berikut beberapa hal yang akan terjadi jika Anda menjadikan akun Anda Akun Pribadi:",
                termList: {
                  li1: "Akun pribadi akan disembunyikan dari publik.",
                  li2: "Hanya orang yang telah menjadi pengikut atau yang telah Anda setujui yang dapat melihat profil Anda.",
                  li3: "Orang yang belum menjadi pengikut Anda atau belum disetujui tidak akan ditambahkan ke daftar kunjungan profil Anda saat mengunjungi profil Anda."
                }
              },

              verifiedAccount: {
                title: "Ketentuan Akun Terverifikasi",
                description: "Kami mempertimbangkan beberapa faktor untuk menentukan apakah akun Anda memenuhi persyaratan kami dan memenuhi syarat untuk verifikasi. Beberapa persyaratan yang akan kami ulas adalah sebagai berikut:",
                termList: {
                  li1: "Anda dapat memberikan dampak positif pada komunitas pengguna WoiLo.",
                  li2: "Anda tidak menggunakan identitas orang lain sebagai identitas pribadi Anda.",
                  li3: "Anda tidak pernah melanggar Pedoman Penggunaan WoiLo.",
                  li4: "Akun Anda mewakili orang, merek, atau entitas yang terkenal dan banyak dicari.",
                  li5: "Akun Anda memiliki foto profil, bio, dan setidaknya 1 postingan.",
                  li6: "Akun Anda harus aktif setidaknya selama 1 bulan di WoiLo.",
                  li7: "Identitas Anda harus jelas, otentik, dan nyata.",
                  li8: "Anda adalah figur publik (selebriti, seniman, dll.) yang memberikan pengaruh positif bagi banyak orang.",
                },
                confirmation: "Jika sudah memenuhi persyaratan yang kami cantumkan, tim moderator WoiLo akan mempertimbangkan lebih lanjut sebelum melakukan proses verifikasi akun. "
              },

              woiloCoin: {
                title: "Ketentuan Penggunaan Koin Woilo",
                subtitle: "Kami memberikan WoiLo Coins kepada pengguna untuk melakukan pembelian dalam aplikasi WoiLo dengan ketentuan sebagai berikut: ",
                termList: {
                  li1: "Koin WoiLo hanya dapat digunakan di aplikasi WoiLo.",
                  li2: "Koin WoiLo tidak dapat diuangkan.",
                  li3: "Semua produk yang telah dibeli dengan WoiLo Coins tidak dapat dikembalikan atau ditukar dengan produk lain.",
                  li4: "Semua aktivitas yang dilakukan untuk menerima Koin WoiLo secara gratis selain dari cara yang kami maksudkan akan ditindaklanjuti dalam bentuk pemblokiran akun Anda."
                }
              },

              woiloWallet: {
                title: "Lo-Wallet",
                description: "WoiLo memberikan kesempatan kepada para pengguna untuk mendapatkan penghasilan berupa bonus berdasarkan interaksi para pengguna dengan konten-konten yang ada di WoiLo. Jumlah penghasilan yang didapat akan dihitung dengan algoritma yang telah dikembangkan oleh WoiLo.",
                subtitle: "Ketentuan Penggunaan",
                subtitleDescription: "Setiap pengguna diharuskan untuk menyetejui Ketentuan Penggunaan dari Lo-Wallet berikut untuk ikut berpartisipasi dalam program ini.",
                termList1: {
                  li1: "Woilo tidak menjanjikan jumlah pendapatan yang akan diperoleh para pengguna. Jumlah pendapatan para pengguna mungkin berbeda setiap harinya.",
                  li2: "Dana yang telah terkumpul pada akun Woilo Anda dapat kami kurangi atau kami hapus jika terdeteksi adanya unsur kecurangan, merusak, dan/atau merugikan pada aktivitas Anda di dalam WoiLo dalam bentuk apapun.",
                  li3: "Dana yang telah terkumpul pada akun Woilo Anda dapat kami kurangi atau kami hapus Jika kami mendeteksi tidak ada aktivitas apa pun pada akun Woilo Anda selama 14 hari terakhir."
                },
                warning: "Setiap pelanggaran yang dilakukan oleh para pengguna yang dapat atau akan merugikan pihak WoiLo maupun pengguna lainnya akan mengakibatkan pemblokiran akun dan pengguna tidak akan menerima penghasilan yang sudah ada.",
                subtitle2: "Penarikan Dana Lo-Wallet",
                termList2: {
                  li1: "Penarikan dana Lo-Wallet hanya dapat dilakukan dengan menggunakan metode pembayaran yang disediakan oleh Woilo meliputi Bank Lokal di Indonesia dan beberapa Dompet Digital yang ada di Indonesia.",
                  li2: "Penarikan dana Lo-Wallet hanya akan dilayani pada hari kerja (Hari Senin sampai Jum'at) dan jam kerja (09.00 WIB sampai 17.00 WIB). Jika Anda melakukan penarikan dana di luar hari kerja dan di luar jam kerja, maka penarikan dana Anda akan diproses pada hari kerja dan jam kerja berikutnya."
                }
              },

              popularityEvent: {
                title: "Popularitas",
                description: "WoiLo menyelenggarakan Event Popularitas untuk memberi kesempatan kepada para pengguna untuk memenangkan hadiah dengan cara mengumpulkan popularitas dan masuk ke peringkat tertinggi. Untuk menjaga keadilan dan kenyamanan para pengguna yang berpartisipasi, kami memberi beberapa peraturan dan ketentuan yang harus ditaati.",
                subtitle: "Ketentuan Event Popularitas",
                subtitleDescription: "Setiap pengguna diharuskan untuk menyetejui Ketentuan Event Popularitas berikut untuk ikut berpartisipasi dalam program ini.",
                termList: {
                  li1: "Konten yang diunggah harus merupakan karya sendiri dan tidak mengambil karya orang lain.",
                  li2: "Dilarang menggunakan akun palsu untuk meningkatkan interaksi dan menaikkan poin Popularitas.",
                  li3: "Dilarang mengganggu kenyamanan pengguna lain untuk tujuan meningkatkan interaksi postingan dan menaikkan poin Popularitas",
                  li4: "Tim moderator dari WoiLo akan selalu melakukan pengecekan dan survey konten maupun interaksi dari setiap pengguna untuk mencegah kecurangan.",
                  li5: "Pengguna yang berpartisipasi dalam Event Popularitas tidak diperbolehkan melanggar Ketentuan Penggunaan WoiLo dalam bentuk apapun"
                },
                warning: "Setiap pelanggaran yang dilakukan oleh para pengguna yang dapat atau akan merugikan pihak WoiLo maupun pengguna lainnya akan mengakibatkan diskualifikasi dari Event Popularitas sampai pemblokiran akun tergantung dengan tingkat keparahan pelanggaran."
              },

              nftTerms: {
                title: "NFT",
                description: "Kami memfasilitasi pembuatan, penjualan, dan pembelian NFT di dalam Woilo. Beberapa ketentuan tentang fitur NFT di Woilo adalah sebagai berikut:",
                termList: {
                  li1: "Anda dapat membuat NFT dari postingan yang Anda unggah di Woilo.",
                  li2: "Anda dapat menjual NFT yang Anda buat di Woilo.",
                  li3: "Anda dapat membeli NFT yang pengguna lain buat di Woilo.",
                  li4: "Pembelian dan penjualan NFT di Woilo menggunakan sistem lelang.",
                  li5: "Pembelian dan penjualan NFT di Woilo menggunakan saldo Lo-Wallet.",
                  li6: "Anda dapat menjual NFT Anda di pasar NFT global ( Opensea ) melalui Woilo jika memenuhi persyaratan.",
                  li7: "Setiap transaksi NFT didalam Woilo akan dikenai PPN sebesar 2% dari nilai transaksi dan pengguna yang menjual NFT wajib membayar PPh sesuai dengan hukum yang berlaku.",
                  li8: "Segala macam kegiatan yang mengandung unsur kecurangan, merusak, dan/atau merugikan pengguna lain atau sistem Woilo akan ditindaklanjuti baik dengan sanksi pemblokiran akun sampai secara hukum tergantung dengan tingkat keparahan pelanggaran."
                },
                taxDocument: "Link dokumen PPN dan PPh transaksi aset kripto",
                description2: "Pembuatan NFT yang pengguna lakukan akan melalui proses pengecekan oleh tim moderator Woilo. Beberapa ketentuan yang harus dipenuhi adalah sebagai berikut:",
                termList2: {
                  li1: "Konten yang akan dijadikan NFT harus merupakan karya sendiri dan tidak mengambil karya orang lain.",
                  li2: "Konten yang akan dijadikan NFT tidak diperbolehkan mengandung unsur eksplisit, pornografi, kekerasan dalam bentuk apapun, fitnah, rasisme, terorisme, dan/atau konten negatif lainnya.",
                  li3: "Pengguna yang ingin membuat NFT tidak diperbolehkan melanggar Ketentuan Penggunaan WoiLo dalam bentuk apapun.",
                },
                confirmation: "Jika sudah memenuhi persyaratan yang kami cantumkan, tim moderator WoiLo akan mempertimbangkan lebih lanjut sebelum melakukan proses pembuatan NFT. "
              }
            },

            helpPage: {
              title: "Butuh Bantuan?",
              subtitle: "Jangan ragu hubungi kami disini ya",
              infoCard: {
                title1: "Untuk pertanyaan bisnis:",
                title2: "Memberi masukan dan Saran:"
              },
              faqTitle: "Isu Paling Dicari",
              faq: {
                question1: "Bagaimana saya bisa mengikuti event popularitas?",
                answer1: "Kamu bisa mengikuti event tiap bulannya dengan cara yang mudah, cukup dengan membagikan karya buatanmu sendiri dan mengajak audiensmu berinteraksi. Event ini dapat diikuti oleh semua orang, tanpa harus memiliki banyak pengikut loh. Yuk ikutan dan dapatkan hadiah tiap bulannya sebesar total 2 Juta Rupiah!",
                question2: "Apakah data pribadi saya terjamin keamanannya?",
                answer2: "Iya dong, di WoiLo kamu gak perlu khawatir datamu bocor. WoiLo dapat memastikan semua data penggunanya aman dan terjamin kerahasiaan privasinya. 1 Juta Pengguna WoiLo sudah membuktikanya loh~",
                question3: "Bagaimana saya bisa mencairkan saldo?",
                answer3: "Kamu bisa mencairkan saldo pada menu Lo-Wallet bagian penarikan saldo lalu kamu bisa pilih metode penarikan dana melalui E-Wallet / Bank. Kamu hanya dapat melakukan penarikan dana dengan minimal saldo Rp 10.000.",
                question4: "Bagaimana cara membuat konten berkualitas dan ramai pengunjung di WoiLo?",
                answer4: "Gampang banget, kamu harus buat konten original alias buatan kamu sendiri. WoiLo tidak merekomendasikan konten yang mengambil dari platform lain. Supaya postingan kamu ramai, ajaklah audiensmu berinteraksi dengan meyukai & mengomentari postinganmu. Mau tau tips selengkapnya? Gabung di WoiLo sekarang dan kita kasih tips ekslusif untuk kamu!",
              }
            },

            contactPage: {
              contact_us: "Hubungi Kami",
              email: "Email",
              full_name: "Nama Lengkap",
              inquiry: "Keperluan",
              entity: "Entitas",
              message: "Pesan",
              company_name: "Nama Institusi / Perusahaan",
              company_website: "Website Institusi / Perusahaan",
              term_check: "Dengan mengisi formulir ini, saya menyetujui bahwa data ini adalah benar adanya. Saya siap ditindaklanjuti secara hukum apabila terbukti adanya kepalsuan data.",
              inquiry_1: "Kerjasama",
              inquiry_2: "Investasi",
              inquiry_3: "Keperluan lainnya",
              entity_1: "Perorangan",
              entity_2: "Institusi / Perusahaan / Organisasi",
              send: "Kirim",
              error: "Tolong lengkapi seluruh data sebelum mengirim",
              success: "Pesan kamu telah terkirim, kami akan menghubungi kami dalam waktu 7 hari ke depan. Terima kasih :)",
              failed: "Ups, terjadi kesalahan. Mohon coba lagi nanti"
            },
            
            blogPage: {
              description: "Mau info & tips pembuatan konten dari 0 secara gratis dari WoiLo? Luangkan waktu 2 menitmu untuk baca blog kami.",
              search: "Cari",
              searchTooltip: "Cari Info & Tips",
              exploreTags: "Jelajahi Tag Lainnya",
              tags: {
                latest: "Terbaru"
              }
            },

            footerSection: {
              menu: "Menu",
              home: "Beranda",
              contact: "Hubungi Kami",
              download: "Unduh Woilo",
              product: "Produk",
              woilo_ads: "Woilo Ads",
              woilo_creator: "Woilo Creator",
              information: "Informasi",
              term_of_use: "Kebijakan Penggunaan",
              privacy_policy: "Kebijakan Privasi"
            }
          }
      }
    }
  });

export default i18n;