import React, { useEffect, useState , useContext} from "react";
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { Link} from "react-router-dom";
import { Spinner } from "react-bootstrap";
import $ from 'jquery';
import { useParams, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import UrlHouse from "../../utils/UrlHouse";
import DefaultProfile from '../../asset/default_profile.png';
import VerifiedBadge from '../../asset/verified_badge.png';
import { AppContext } from "../../utils/AppContext";
import Logo from '../../asset/logo_long_transparent_cropped.png';
import "../../spinner.css";

import {Helmet} from "react-helmet";
import axios from 'axios';
import { Label } from "recharts";

const FinishAdPage = () => {

    const appContext = useContext(AppContext);
    const navigate = useNavigate();
    const params = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const [adType, setAdType] = useState(101);
    const [adName, setAdName] = useState("");
    const [isUploading, setIsUploading] = useState(false);
    const [uploadingMessage, setUploadingMessage] = useState("");

    const budgetMin = 50000;
    const budgetMax = 10000000;

    const desktopStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px"
        },

        selectImageButtonStyle: {
            display: "none"
        },

        imagePreviewStyle: {
            height: "30vh",
            margin: "10px"
        },

        sendButtonStyle: {
            
        }
    }

    const mobileStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px",
        },

        selectImageButtonStyle: {
            display: 'none'
        },

        imagePreviewStyle: {
            height: "30vh",
            margin: "10px"
        },

        sendButtonStyle: {
            
        }
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    useEffect(() => {
      init();
    }, [])

    const init = () => {
        $.ajax({
            type: "POST",
            url: UrlHouse.FINISH_AD_INIT,
            data: JSON.stringify({
                email: localStorage.getItem("email"), password: localStorage.getItem("password"), ad_id: params.id
            }),
            dataType: "json",
            success: function (response) {
                if(response.result === 1){
                    setIsLoaded(true);
                    setAdType(response.ad_type);
                    setAdName(response.ad_name);
                }else if(response.result === 2){
                    navigate('/dashboard/ads/create/confirm/'+params.id);
                }else{
                    navigate('/dashboard/ads/create/setup/'+params.id);
                }
            },
            error: function(e){
                navigate('/dashboard/ads');
            }
        });
    }

    const [linkPreview, setLinkPreview] = useState({
        title: "Woilo - Photo, Video, Chat, NFTs",
        description: "Woilo - Photo, Video, Chat, NFTs",
        url: "",
        imageUrl: ""
    })

	const onSubmitButtonClick = async() => {
        setIsUploading(true);
        setUploadingMessage("Menyelesaikan Iklan");
        $.ajax({
            type: "POST",
            url: UrlHouse.FINISH_AD,
            data: JSON.stringify({
                email: localStorage.getItem("email"),
                password: localStorage.getItem("password"),
                ad_id: params.id,
                start_date: adStartDate,
                end_date: adEndDate,
                budget: adBudget
            }),
            dataType: "json",
            success: function (response) {
                setIsUploading(false);
                if(response.result === 1){
                    navigate('/dashboard/ads/create/confirm/'+params.id);
                }else{
                    alert("Ups! Terjadi masalah saat membuat iklan. Silahkan coba lagi nanti");
                }
            },
            error: function(e){
                setIsUploading(false);
                alert("Ups! Terjadi masalah saat membuat iklan. Silahkan coba lagi nanti");
            }
        });
	};

    const [adStartDate, setAdStartDate] = useState("");
    const [startDateMessage, setStartDateMessage] = useState("");
    const [adEndDate, setAdEndDate] = useState("");
    const [endDateMessage, setEndDateMessage] = useState("");
    const [endDateError, setEndDateError] = useState("");
    const [adBudget, setAdBudget] = useState(0);
    const [budgetMessage, setBudgetMessage] = useState("");
    const [budgetError, setBudgetError] = useState("");

    const onFormChange = (e) => {
        let target = e.target.name;
        let value = e.target.value;
        if(value.length > 0){
            if(target === "input_start_date"){
                setStartDateMessage("Iklan ini akan dimulai pada tanggal " + value);
                let date = value.replace(/-/gi, "/");
                setAdStartDate(date);
            }
            if(target === "input_end_date"){
                let date = value.replace(/-/gi, "/");
                let date_1 = new Date(adStartDate);
                let date_2 = new Date(date);
                if(date_2 <= date_1){
                    setEndDateError("Iklan harus berjalan paling tidak 1 hari");
                    setAdEndDate(0);
                }else{
                    let difference = date_2.getTime() - date_1.getTime();
                    let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
                    setEndDateError("");
                    setEndDateMessage("Iklan ini akan berjalan selama " + totalDays + " hari dan berakhir pada tanggal " + value);
                    setAdEndDate(date);
                }
            }
            if(target === "input_budget"){
                let budget = parseInt(value);
                if(budget < budgetMin){
                    setBudgetError("Biaya iklan tidak boleh kurang dari Rp.50.000");
                    setAdBudget(0);
                    setBudgetMessage("");
                }else if(budget > budgetMax){
                    setBudgetError("Biaya iklan tidak boleh lebih dari Rp.50.000");
                    setAdBudget(0);
                    setBudgetMessage("");
                }else{
                    setBudgetError("");
                    setAdBudget(budget);
                    const formatter = new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                    });
                    setBudgetMessage("Biaya total dari iklan ini tidak akan lebih dari " + formatter.format(budget));
                }
            }
        }else{
            if(target === "input_start_date"){
                setStartDateMessage("");
                setAdStartDate("");
            }
            if(target === "input_end_date"){
                setEndDateError("");
                setEndDateMessage("");
                setAdEndDate("");
            }
            if(target === "input_budget"){
                setAdBudget(0);
            }
        }
    }

    function getAdType(){
        if(adType === 101){
            return "Iklan Foto";
        }
        if(adType === 102){
            return "Iklan Video";
        }
        if(adType === 103){
            return "Iklan Karosel";
        }
        return "Iklan Foto";
    }

    const onBack = () => {
        navigate('/dashboard/ads')
    }

    return(
        <>
            <div style={styles.outerContainerStyle}>
                <Helmet>
                    <title>{linkPreview.title}</title>

                    <meta property="og:title" content={linkPreview.title} />
                    <meta property="og:description" content={linkPreview.description} />
                    <meta property="og:image" content={linkPreview.imageUrl} />
                    <meta property="og:url" content={linkPreview.url} />
                    <meta property="og:type" content="website"/>

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:domain" content="woilo.com" />
                    <meta property="twitter:url" content={linkPreview.url} />
                    <meta name="twitter:title" content={linkPreview.title} />
                    <meta name="twitter:description" content={linkPreview.description} />
                    <meta name="twitter:image" content={linkPreview.imageUrl} />
                </Helmet>
                {
                    isLoaded?<div style={styles.mainContainerStyle}>
                        <h1>Atur Jadwal dan Biaya Iklan</h1>
                        <h4>{adName}  •  {getAdType()}</h4>
                        <hr></hr>
                        <div>
                            <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Tanggal Mulai Iklan</Form.Label>
                                {startDateMessage.length > 0?<div style={{marginBottom: "10px", color: appContext.colors.primaryGrey}}>{startDateMessage}</div>:<></>}
                                <Form.Control onChange={onFormChange} name="input_start_date" type="date" min='2022-11-04'/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Tanggal Selesai Iklan</Form.Label>
                                {startDateMessage.length > 0 && endDateError.length === 0?<div style={{marginBottom: "10px", color: appContext.colors.primaryGrey}}>{endDateMessage}</div>:<></>}
                                {endDateError.length > 0?<div style={{marginBottom: "10px", color: "red"}}>{endDateError}</div>:<></>}
                                <Form.Control onChange={onFormChange} name="input_end_date" type="date" min='2022-11-04'/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Pengeluaran Iklan</Form.Label>
                                {budgetMessage.length > 0 && budgetError.length === 0?<div style={{marginBottom: "10px", color: appContext.colors.primaryGrey}}>{budgetMessage}</div>:<></>}
                                {budgetError.length > 0?<div style={{marginBottom: "10px", color: "red"}}>{budgetError}</div>:<></>}
                                <Form.Control onChange={onFormChange} name="input_budget" type="number" step="0.01" min="50000" max="10000000"/>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Col sm={{ span: 12}} style={{display: "flex"}}>
                                    <Button onClick={onSubmitButtonClick} disabled={adStartDate.length === 0 || adEndDate.length === 0 || adBudget === 0 || isUploading} style={styles.sendButtonStyle}>Selesai</Button>
                                    {isUploading?<div className="loading-spinner" style={{marginTop: "5px", marginLeft: "10px"}}></div>:<></>}
                                    {isUploading?<div style={{marginTop: "5px", marginLeft: "10px"}}>{uploadingMessage}</div>:<></>}
                                    <Button onClick={onBack} style={{backgroundColor: appContext.colors.primaryWhite, color: appContext.colors.primaryBlue, marginLeft: "10px"}}>Kembali</Button>
                                </Col>
                            </Form.Group>
                        </Form>
                        </div>
                    </div>:<></>
                    
                }
            </div>
        </>
    )
}

export default FinishAdPage;