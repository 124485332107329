import React, { useEffect, useState , useContext} from "react";
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { Link} from "react-router-dom";
import { Spinner } from "react-bootstrap";
import $ from 'jquery';
import { useParams } from "react-router-dom";
import ReactGA from "react-ga4";
import UrlHouse from "../../utils/UrlHouse";
import DefaultProfile from '../../asset/default_profile.png';
import VerifiedBadge from '../../asset/verified_badge.png';
import { AppContext } from "../../utils/AppContext";
import { useNavigate } from "react-router-dom";
import Logo from '../../asset/logo_long_transparent_cropped.png';

import {Helmet} from "react-helmet";

const TopupPage = () => {
    const appContext = useContext(AppContext);
    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = useState(false);

    const minTopup = 100000;
    const maxTopup = 1000000000;

    const desktopStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px"
        },
    }

    const mobileStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px",
        },
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    useEffect(() => {
      init();
    }, [])

    const init = () => {
        $.ajax({
            type: "POST",
            url: UrlHouse.TOPUP_INIT,
            data: JSON.stringify({
                email: localStorage.getItem("email"), password: localStorage.getItem("password")
            }),
            dataType: "json",
            success: function (response) {
                setIsLoaded(true);
                if(response.result === 1){
                    setIsLoaded(true);
                }else if(response.result === 2){
                    navigate('/dashboard/balance/topup/payment');
                }else{
                    navigate('/dashboard/balance');
                }
            },
            error: function(e){
                navigate('/dashboard/balance');
            }
        });
    }

    const [linkPreview, setLinkPreview] = useState({
        title: "Woilo - Photo, Video, Chat, NFTs",
        description: "Woilo - Photo, Video, Chat, NFTs",
        url: "",
        imageUrl: ""
    })

    const [topupAmount, setTopupAmount] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");

    const onFormChange = (e) => {
        let target = e.target.name;
        let value = e.target.value;
        if(target === "input_amount"){
            if(value.length > 0){
                setTopupAmount(value);
                if(value >= minTopup && value <= maxTopup){
                    setErrorMessage("");
                }else{
                    setErrorMessage("Jumlah penambahan saldo harus lebih atau sama dengan Rp.100.000");
                }
            }else{
                setTopupAmount(0);
                setErrorMessage("");
            }
        }
    }

    const onSubmitButtonClick = () => {
        $.ajax({
            type: "POST",
            url: UrlHouse.TOPUP,
            data: JSON.stringify({
                email: localStorage.getItem("email"), password: localStorage.getItem("password"), topup_amount: topupAmount
            }),
            dataType: "json",
            success: function (response) {
                if(response.result === 1){
                    navigate('/dashboard/balance/topup/payment');
                }else{
                    alert("Ups! Terjadi masalah saat menambah saldo. Silahkan coba lagi nanti");
                }
            },
            error: function(e){
                alert("Ups! Terjadi masalah saat menambah saldo. Silahkan coba lagi nanti");
            }
        });
    }

    return(
        <>
            <div style={styles.outerContainerStyle}>
                <Helmet>
                    <title>{linkPreview.title}</title>

                    <meta property="og:title" content={linkPreview.title} />
                    <meta property="og:description" content={linkPreview.description} />
                    <meta property="og:image" content={linkPreview.imageUrl} />
                    <meta property="og:url" content={linkPreview.url} />
                    <meta property="og:type" content="website"/>

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:domain" content="woilo.com" />
                    <meta property="twitter:url" content={linkPreview.url} />
                    <meta name="twitter:title" content={linkPreview.title} />
                    <meta name="twitter:description" content={linkPreview.description} />
                    <meta name="twitter:image" content={linkPreview.imageUrl} />
                </Helmet>
                {
                    isLoaded?<div style={styles.mainContainerStyle}>
                        <h1>Tambah Saldo</h1>
                        <hr></hr>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Jumlah Saldo Yang Diinginkan</Form.Label>
                                {
                                    errorMessage.length > 0?<p style={{color: "red"}}>{errorMessage}</p>:<></>
                                }
                                <Form.Control onChange={onFormChange} name="input_amount" type="number" placeholder="100.000"/>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Col sm={{ span: 12}}>
                                    <Button onClick={onSubmitButtonClick} disabled={topupAmount === 0 || errorMessage.length > 0} style={styles.sendButtonStyle}>Tambah Saldo</Button>
                                </Col>
                            </Form.Group>
                        </Form>
                    </div>
                    :
                    <></>
                    
                }
            </div>
        </>
    )
}

export default TopupPage;