import React, { useEffect, useState , useContext} from "react";
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { Link} from "react-router-dom";
import { Spinner } from "react-bootstrap";
import $ from 'jquery';
import { useParams } from "react-router-dom";
import ReactGA from "react-ga4";
import UrlHouse from "../../utils/UrlHouse";
import DefaultProfile from '../../asset/default_profile.png';
import VerifiedBadge from '../../asset/verified_badge.png';
import { AppContext } from "../../utils/AppContext";
import { useNavigate } from "react-router-dom";
import Logo from '../../asset/logo_long_transparent_cropped.png';

import {Helmet} from "react-helmet";

const CreateMessagePage = () => {
    const appContext = useContext(AppContext);
    const navigate = useNavigate();

    const desktopStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px"
        },
    }

    const mobileStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px",
        },
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    useEffect(() => {
      //$('body').css('background-color','#2E80EB');
      
    }, [])

    const [linkPreview, setLinkPreview] = useState({
        title: "Woilo - Photo, Video, Chat, NFTs",
        description: "Woilo - Photo, Video, Chat, NFTs",
        url: "",
        imageUrl: ""
    })

    const [messageName, setMessageName] = useState("");
    const [messageType, setMessageType] = useState("");

    const onFormChange = (e) => {
        let target = e.target.name;
        let value = e.target.value;
        if(target === "input_name"){
            setMessageName(value);
        }
        if(target === "input_type"){
            setMessageType(value);
        }
    }

    const onSubmitButtonClick = () => {
        $.ajax({
            type: "POST",
            url: UrlHouse.CREATE_MESSAGE,
            data: JSON.stringify({
                email: localStorage.getItem("email"), password: localStorage.getItem("password"), message_name: messageName, message_type: messageType
            }),
            dataType: "json",
            success: function (response) {
                if(response.result === 1){
                    navigate('/dashboard/message_ads/create/setup/'+response.message_id);
                }else{
                    alert("Ups! Terjadi masalah saat membuat pesan. Silahkan coba lagi nanti");
                }
            },
            error: function(e){
                alert("Ups! Terjadi masalah saat membuat pesan. Silahkan coba lagi nanti");
            }
        });
    }

    return(
        <>
            <div style={styles.outerContainerStyle}>
                <Helmet>
                    <title>{linkPreview.title}</title>

                    <meta property="og:title" content={linkPreview.title} />
                    <meta property="og:description" content={linkPreview.description} />
                    <meta property="og:image" content={linkPreview.imageUrl} />
                    <meta property="og:url" content={linkPreview.url} />
                    <meta property="og:type" content="website"/>

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:domain" content="woilo.com" />
                    <meta property="twitter:url" content={linkPreview.url} />
                    <meta name="twitter:title" content={linkPreview.title} />
                    <meta name="twitter:description" content={linkPreview.description} />
                    <meta name="twitter:image" content={linkPreview.imageUrl} />
                </Helmet>
                {
                    <div style={styles.mainContainerStyle}>
                        <h1>Buat Kampanye Pesan</h1>
                        <hr></hr>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Nama Kampanye Pesan</Form.Label>
                                <Form.Control onChange={onFormChange} name="input_name" type="text"/>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label as="legend" column sm={1} style={styles.businessInquiryFormRadioLabelStyle}>
                                    Jenis Pesan
                                </Form.Label>
                                <Col sm={4}>
                                    <Form.Check
                                        type="radio"
                                        label="Pesan Teks"
                                        onChange={onFormChange}
                                        name="input_type"
                                        value="text"
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Pesan Foto"
                                        onChange={onFormChange}
                                        name="input_type"
                                        value="image"
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Pesan Video"
                                        onChange={onFormChange}
                                        name="input_type"
                                        value="video"
                                    />
                                </Col>
                            </Form.Group>
                            <Link style={{textDecoration: "none", color: appContext.colors.primaryBlue}} to="../tos">
                                <p style={{marginTop: "5px"}}>
                                    Pastikan kamu telah membaca Kebijakan Penggunaan & Kebijakan Layanan
                                </p>
                            </Link>
                            <Form.Group as={Row} className="mb-3">
                                <Col sm={{ span: 12}}>
                                    <Button onClick={onSubmitButtonClick} disabled={messageName.length === 0 || messageType.length === 0} style={styles.sendButtonStyle}>Buat Pesan</Button>
                                </Col>
                            </Form.Group>
                        </Form>
                    </div>
                    
                }
            </div>
        </>
    )
}

export default CreateMessagePage;