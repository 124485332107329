import React from "react";
import { useTranslation } from "react-i18next";

const AdvertisingTerms = (props) => {
    
    const styles = props.styles
    const { t } = useTranslation();

    return(
        <>
            <p style={styles.tosContentLastChangedStyle}>{t('termsOfServicePage.lastChanged')}</p>
            <p style={styles.tosContentDescriptionStyle}>
                Ketentuan Layanan Beriklan ini menjelaskan ketentuan dan kebijakan beriklan bagi para pengiklan di WoiloAds.
                <br></br><br></br>
                Dengan mendaftar sebagai pengiklan di WoiloAds, Anda menyetujui dan mematuhi Ketentuan Layanan Beriklan di WoiloAds.
            </p>
            <h3 style={styles.tosContentTitleStyle}>Pedoman Beriklan</h3>

            <h4 style={styles.tosContentSubtitleStyle}>Penolakan Iklan</h4>
            <p style={styles.tosContentDescriptionStyle}>
                Kami akan meninjau semua media iklan berupa Foto, Video, dan Deskripsi yang telah dibuat oleh pengiklan. Kami berhak untuk menolak iklan dari para pengiklan apabila media iklan dari pengiklan melanggar Pedoman Penggunaan Woilo.
            </p>
            <h4 style={styles.tosContentSubtitleStyle}>Pembayaran Iklan</h4>
            <p>
                Kami telah menyediakan beberapa metode pembayaran termasuk Virtual Account BRI, Virtual Account Mandiri, Transfer Bank BCA. Dana yang telah masuk ke akun iklan Anda tidak dapat diuangkan kembali.
            </p>
            <h4 style={styles.tosContentSubtitleStyle}>Penghapusan Akun Iklan</h4>
            <p>
                Kami berhak untuk menghapus atau memblokir Akun Iklan apabila kami menemukan tindakan mencurigakan dari pengiklan yang dapat mengganggu, merusak, atau merubah sistem layanan platform Woilo dan/atau WoiloAds.
            </p>

            <h3 style={styles.tosContentTitleStyle}>Kewajiban Pengiklan</h3>
            
            <h4 style={styles.tosContentSubtitleStyle}>Kelayakan Media Iklan</h4>
            <p style={styles.tosContentDescriptionStyle}>
                Pengiklan diwajibkan untuk menggunakan media iklan yang tidak melanggar UUD dan/atau Peraturan Pemerintah meliputi namun tidak terbatas pada teks, deskripsi, foto, video, maupun media lainnya yang akan digunakan sebagai media iklan.
            </p>
            <h4 style={styles.tosContentSubtitleStyle}>Memiliki Legalitas atau Izin Usaha</h4>
            <p>
                Pengiklan diwajibkan untuk memiliki izin usaha sebelum mempromosikan produk atau layanannya melalui WoiloAds.
            </p>
            <h4 style={styles.tosContentSubtitleStyle}>Tanggung Jawab Hukum</h4>
            <p>
                Apabila produk atau usaha yang dipromosikan melalui WoiloAds melanggar UUD dan/atau Peraturan Pemerintahan, pengiklan wajib bertanggung jawab secara hukum.
            </p>
            
            <h3 style={styles.tosContentTitleStyle}>{t('termsOfServicePage.changesDisclaimer.title')}</h3>
            
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.changesDisclaimer.content.p1')}</p>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.changesDisclaimer.content.p2')}</p>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.changesDisclaimer.content.p3')}</p>
        </>
    )
}

export default AdvertisingTerms;