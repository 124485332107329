import React, { useEffect, useState , useContext} from "react";
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { Link} from "react-router-dom";
import { Spinner } from "react-bootstrap";
import $ from 'jquery';
import { useParams, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import UrlHouse from "../../utils/UrlHouse";
import DefaultProfile from '../../asset/default_profile.png';
import VerifiedBadge from '../../asset/verified_badge.png';
import { AppContext } from "../../utils/AppContext";
import Logo from '../../asset/logo_long_transparent_cropped.png';
import "../../spinner.css";

import {Helmet} from "react-helmet";
import axios from 'axios';
import { Label } from "recharts";

const SetupMessagePage = () => {

    const appContext = useContext(AppContext);
    const navigate = useNavigate();
    const params = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const [advertiserPicture, setAdvertiserPicture] = useState("https://sestyc.com/sestyc/compressed_display_picture/ZGlzcGxheTIwMjIxMTIzMDUwNTEzNDY4MTAwcGljdHVyZXNz.jpeg")
    //const [advertiserPicture, setAdvertiserPicture] = useState("");
    const [advertiserName, setAdvertiserName] = useState("WoiloAds")
    const [messageType, setMessageType] = useState(103);
    const [messageName, setMessageName] = useState("Test Name");
    const [isUploading, setIsUploading] = useState(false);
    const [uploadingMessage, setUploadingMessage] = useState("");

    const desktopStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px"
        },

        selectImageButtonStyle: {
            display: "none"
        },

        imagePreviewStyle: {
            width: "280px"
        },

        sendButtonStyle: {
            
        }
    }

    const mobileStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px",
        },

        selectImageButtonStyle: {
            display: 'none'
        },

        imagePreviewStyle: {
            width: "280px"
        },

        sendButtonStyle: {
            
        }
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    useEffect(() => {
      init();
    }, [])

    const init = () => {
        $.ajax({
            type: "POST",
            url: UrlHouse.SETUP_MESSAGE_INIT,
            data: JSON.stringify({
                email: localStorage.getItem("email"), password: localStorage.getItem("password"), message_id: params.id
            }),
            dataType: "json",
            success: function (response) {
                if(response.result === 1){
                    setIsLoaded(true);
                    setMessageType(response.message_type);
                    setMessageName(response.message_name);
                    setAdvertiserName(response.advertiser_name);
                    setAdvertiserPicture(response.advertiser_picture);
                }else if(response.result === 2){
                    navigate('/dashboard/message_ads/create/finish/'+params.id);
                }else{
                    navigate('/dashboard/message_ads');
                }
            },
            error: function(e){
                navigate('/dashboard/message_ads');
            }
        });
    }

    const [linkPreview, setLinkPreview] = useState({
        title: "Woilo - Photo, Video, Chat, NFTs",
        description: "Woilo - Photo, Video, Chat, NFTs",
        url: "",
        imageUrl: ""
    })

    const [selectedImageFile, setSelectedImageFile] = useState();
    const [selectedImage, setSelectedImage] = useState(null);

    const [selectedVideoFile, setSelectedVideoFile] = useState();
    const [selectedVideo, setSelectedVideo] = useState(null);

    const [selectedCarouselFiles, setSelectedCarouselFiles] = useState([]);
    const [selectedCarousels, setSelectedCarousels] = useState([]);

	const [isFilePicked, setIsFilePicked] = useState(false);

    const onFileChange = (event) => {
        var file = event.target.files[0];
        if(checkFileSize(file, 10)){
            setSelectedImageFile(event.target.files[0]);
            setSelectedImage(URL.createObjectURL(event.target.files[0]));
        }else{
            alert("Ukuran File Terlalu Besar")
        }
	};

    function checkFileSize(file, limitMb){
        var limitByte = limitMb * 1024 * 1024;
        return file.size <= limitByte;
    }

    const onFileChangeVideo = (event) => {
        var file = event.target.files[0];
        if(checkFileSize(file, 25)){
            setSelectedVideoFile(event.target.files[0]);
            setSelectedVideo(URL.createObjectURL(event.target.files[0]));
        }else{
            alert("Ukuran File Terlalu Besar")
        }
    }

    const onFileChangeCarousel = (event) => {
        var file = event.target.files[0];
        if(checkFileSize(file, 10)){
            var image = new Image();
            image.onload = function () {
                if(this.width === this.height){
                    let tmpFiles = selectedCarouselFiles;
                    let tmpCarousels = selectedCarousels;
            
                    tmpFiles.push(event.target.files[0]);
                    tmpCarousels.push(URL.createObjectURL(event.target.files[0]))
            
                    setSelectedCarouselFiles(tmpFiles);
                    setSelectedCarousels(tmpCarousels);
            
                    setTimeout(function() {
                        setIsFilePicked(!isFilePicked);
                    }, 100);
                }else{
                    alert("Rasio Foto harus 1 : 1");
                }
            };
            image.src = URL.createObjectURL(file);
        }else{
            alert("Ukuran File Terlalu Besar");
        }
        
	};

	const onSubmitButtonClick = async() => {
        if(messageType === 101){
            setupTextMessage();
        }else if(messageType === 102){
            setupImageMessage();
        }else if(messageType === 103){
            setupVideoMessage();
        }
	};

    const setupTextMessage = async() => {
        setIsUploading(true)
        setUploadingMessage("Mengatur Komponen Pesan...");
        $.ajax({
            type: "POST",
            url: UrlHouse.SETUP_MESSAGE,
            data: JSON.stringify({
                email: localStorage.getItem("email"),
                password: localStorage.getItem("password"),
                message_id: params.id,
                text_message: textMessage,
            }),
            dataType: "json",
            success: function (response) {
                setIsUploading(false);
                setUploadingMessage("");
                if(response.result === 1){
                    navigate('/dashboard/message_ads/create/finish/'+params.id);
                }else{
                    alert("Ups! Terjadi masalah saat membuat pesan. Silahkan coba lagi nanti");
                }
            },
            error: function(e){
                setIsUploading(false);
                setUploadingMessage("");
                alert("Ups! Terjadi masalah saat membuat pesan. Silahkan coba lagi nanti");
            }
        });
    }

    const setupImageMessage = async() => {
        setIsUploading(true);
        setUploadingMessage("Mengunggah Foto...");
        
        let formData = new FormData();
        formData.append("image_file", selectedImageFile);

        let authParam = "?email="+localStorage.getItem("email")+"&password="+localStorage.getItem("password")+"&message_id="+params.id;
        

        try {
            const response = await axios({
                method: "post",
                url: UrlHouse.UPLOAD_IMAGE_MESSAGE+authParam,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
                
            });
            
            let decodedResponse = response.data;

            if(decodedResponse.result === 1){
                setUploadingMessage("Mengatur Komponen Pesan...");

                let imageName = decodedResponse.image_name;
    
                $.ajax({
                    type: "POST",
                    url: UrlHouse.SETUP_MESSAGE_IMAGE,
                    data: JSON.stringify({
                        email: localStorage.getItem("email"),
                        password: localStorage.getItem("password"),
                        message_id: params.id,
                        text_message: textMessage,
                        image_message: imageName
                    }),
                    dataType: "json",
                    success: function (response) {
                        if(response.result === 1){
                            navigate('/dashboard/message_ads/create/finish/'+params.id);
                        }else{
                            alert("Ups! Terjadi masalah saat membuat pesan. Silahkan coba lagi nanti");
                        }
                    },
                    error: function(e){
                        alert("Ups! Terjadi masalah saat membuat pesan. Silahkan coba lagi nanti");
                    }
                });
            }else{
                alert("Ups! Terjadi masalah saat membuat mengunggah foto, coba foto lain");
            }
        } catch(error) {
            alert("Ups! Terjadi masalah saat membuat mengunggah foto, coba foto lain");
        }
        setIsUploading(false);
        setUploadingMessage("");
    };

    const setupVideoMessage = async() => {
        setIsUploading(true);
        setUploadingMessage("Mengunggah Video...");
        
        let formData = new FormData();
        formData.append("video_file", selectedVideoFile);

        let authParam = "?email="+localStorage.getItem("email")+"&password="+localStorage.getItem("password")+"&message_id="+params.id;
        

        try {
            const response = await axios({
                method: "post",
                url: UrlHouse.UPLOAD_VIDEO_MESSAGE+authParam,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
                
            });
            
            let decodedResponse = response.data;

            if(decodedResponse.result === 1){
                setUploadingMessage("Mengatur Komponen Pesan...");

                let videoName = decodedResponse.video_name;
    
                $.ajax({
                    type: "POST",
                    url: UrlHouse.SETUP_MESSAGE_VIDEO,
                    data: JSON.stringify({
                        email: localStorage.getItem("email"),
                        password: localStorage.getItem("password"),
                        message_id: params.id,
                        text_message: textMessage,
                        video_message: videoName
                    }),
                    dataType: "json",
                    success: function (response) {
                        if(response.result === 1){
                            navigate('/dashboard/message_ads/create/finish/'+params.id);
                        }else{
                            alert("Ups! Terjadi masalah saat membuat pesan. Silahkan coba lagi nanti");
                        }
                    },
                    error: function(e){
                        alert("Ups! Terjadi masalah saat membuat pesan. Silahkan coba lagi nanti");
                    }
                });
            }else{
                alert("Ups! Terjadi masalah saat membuat mengunggah video, coba video lain");
            }
        } catch(error) {
            alert("Ups! Terjadi masalah saat membuat mengunggah video, coba video lain");
        }
        setIsUploading(false);
        setUploadingMessage("");
    };

    const [textMessage, setTextMessage] = useState("");

    const onFormChange = (e) => {
        let target = e.target.name;
        let value = e.target.value;
        if(target === "input_message"){
            setTextMessage(value);
            $('#text_message_preview').html(value.replaceAll('\n','<br/>'))
        }
    }

    const hiddenFileInput = React.useRef(null);

    function getMessageType(){
        if(messageType === 101){
            return "Pesan Teks";
        }
        if(messageType === 102){
            return "Pesan Foto";
        }
        if(messageType === 103){
            return "Pesan Video";
        }
        return "Pesan Teks";
    }

    function getMediaInputButton(){
        if(messageType === 102){
            return (
                <>
                    <Button onClick={() => {hiddenFileInput.current.click()}}>
                        {selectedImage != null?"Pilih Foto Lain":"Pilih Foto"}
                    </Button>
                </>
            )
        }else if(messageType === 103){
            return (
                <Button onClick={() => {hiddenFileInput.current.click()}}>
                    {selectedVideo != null?"Pilih Video Lain":"Pilih Video"}
                </Button>
            )
        }else if(messageType === 103){
            return (
                <>
                    {selectedCarouselFiles.length < 5?<Button style={{marginRight: "10px"}} onClick={() => {hiddenFileInput.current.click()}}>
                        {selectedCarouselFiles.length > 0?"Tambahkan Foto":"Pilih Foto"}
                    </Button>:<></>}
                    {selectedCarouselFiles.length > 0?<Button style={{backgroundColor: appContext.colors.primaryWhite, color: appContext.colors.primaryBlue}}
                        onClick={() => {
                            setSelectedCarouselFiles([]);
                            setSelectedCarousels([]);
                        }}>
                        {selectedCarouselFiles.length > 1?"Hapus Semua Foto":"Hapus Foto"}
                    </Button>:<></>}
                </>
            )
        }
        return (
            <></>
        )
    }

    function getMediaPreview(){
        if(messageType === 102){
            return (
                <>
                    <input style={styles.selectImageButtonStyle} ref={hiddenFileInput} accept="image/png, image/jpeg" type="file" onChange={onFileChange} />
                    <br></br>
                    <label style={{fontWeight: "300", marginTop: "5px"}}>Format Foto yang dapat digunakan adalah JPEG, JPG, dan PNG. Dengan ukuran tidak lebih dari 10 MB</label>
                </>
            )
        }else if(messageType === 103){
            return (
                <>
                    <input style={styles.selectImageButtonStyle} ref={hiddenFileInput} accept="video/mp4" type="file" onChange={onFileChangeVideo} />
                    <br></br>
                    <label style={{fontWeight: "300", marginTop: "5px"}}>Format Video yang dapat digunakan adalah MP4. Dengan ukuran tidak lebih dari 25MB</label>
                </>
            )
        }else if(messageType === 102){
            return (
                <>
                    {selectedCarousels.length < 5?<input style={styles.selectImageButtonStyle} ref={hiddenFileInput} accept="image/png, image/jpeg" type="file" onChange={onFileChangeCarousel} />:<></>}
                    <br></br>
                    <label style={{fontWeight: "300", marginTop: "5px"}}>Pilih hingga 5 Foto. Format Foto yang dapat digunakan adalah JPEG, JPG, dan PNG. Dengan ukuran tidak lebih dari 10 MB dan rasio Foto 1 : 1</label>
                </>
            )
        }
        return (
            <></>
        )
    }

    function mediaSelected(){
        if(messageType === 101){
            return true;
        }
        if(selectedCarousels.length > 1){
            return true;
        }
        if(selectedImage != null){
            return true;
        }
        if(selectedVideo != null){
            return true;
        }
        return false;
    }

    function getMessagePreview(){
        const containerStyle = {
            width: "400px",
            borderRadius: "5px",
            borderColor: appContext.colors.primaryGrey,
            borderStyle: "solid",
            borderWidth: "1px"
        };
        const displayPictureStyle = {
            width: "50px",
            borderRadius: "50%",
            alignSelf: "center",
            borderColor: appContext.colors.primaryGrey,
            borderStyle: "solid",
            borderWidth: "0.5px"
        };
        const displayNameStyle = {
            marginLeft: "10px",
            marginTop: "10px",
            size: "20px"
        }
        const messageBubble = {
            backgroundColor: appContext.colors.primaryBlue,
            width: "300px",
            margin: "10px",
            paddingLeft: "10px",
            paddingTop: "10px",
            paddingRight: "10px",
            paddingBottom: "10px",
            fontWeight: "400",
            borderRadius: "10px",
            color: appContext.colors.primaryWhite
        }
        if(messageType === 101){
            return (
                <>
                    <div style={containerStyle}>
                        <div style={{display: "flex", paddingLeft: "10px", paddingTop: "10px", paddingRight: "10px"}}>
                            <img style={displayPictureStyle} src={advertiserPicture} alt="profilePicture"/>
                            <h4 style={displayNameStyle}>{advertiserName}</h4>
                        </div>
                        <hr></hr>
                        <div id="text_message_preview" style={messageBubble}></div>
                    </div>
                </>
            )
        }
        if(messageType === 102){
            return (
                <>
                    <div style={containerStyle}>
                        <div style={{display: "flex", paddingLeft: "10px", paddingTop: "10px", paddingRight: "10px"}}>
                            <img style={displayPictureStyle} src={advertiserPicture} alt="profilePicture"/>
                            <h4 style={displayNameStyle}>{advertiserName}</h4>
                        </div>
                        <hr></hr>
                        <div style={messageBubble}>
                            {selectedImage != null?<img style={styles.imagePreviewStyle} src={selectedImage} alt="preview" />:<div></div>}
                        </div>
                        <div id="text_message_preview" style={messageBubble}></div>
                    </div>
                </>
            )
        }
        if(messageType === 103){
            return (
                <>
                    <div style={containerStyle}>
                        <div style={{display: "flex", paddingLeft: "10px", paddingTop: "10px", paddingRight: "10px"}}>
                            <img style={displayPictureStyle} src={advertiserPicture} alt="profilePicture"/>
                            <h4 style={displayNameStyle}>{advertiserName}</h4>
                        </div>
                        <hr></hr>
                        <div style={messageBubble}>
                            {
                                selectedVideo != null?<video style={styles.imagePreviewStyle} playsInline controls>
                                    <source src={selectedVideo} type="video/mp4"/>
                                </video>:<div></div>
                            }
                        </div>
                        <div id="text_message_preview" style={messageBubble}></div>
                    </div>
                </>
            )
        }
        return (
            <></>
        )
    }

    const onBack = () => {
        navigate('/dashboard/message_ads')
    }

    return(
        <>
            <div style={styles.outerContainerStyle}>
                <Helmet>
                    <title>{linkPreview.title}</title>

                    <meta property="og:title" content={linkPreview.title} />
                    <meta property="og:description" content={linkPreview.description} />
                    <meta property="og:image" content={linkPreview.imageUrl} />
                    <meta property="og:url" content={linkPreview.url} />
                    <meta property="og:type" content="website"/>

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:domain" content="woilo.com" />
                    <meta property="twitter:url" content={linkPreview.url} />
                    <meta name="twitter:title" content={linkPreview.title} />
                    <meta name="twitter:description" content={linkPreview.description} />
                    <meta name="twitter:image" content={linkPreview.imageUrl} />
                </Helmet>
                {
                    isLoaded?<div style={styles.mainContainerStyle}>
                        <h1>Buat Media Pesan</h1>
                        <h4>{messageName}  •  {getMessageType()}</h4>
                        <hr></hr>
                        {getMediaInputButton()}
                        <div>
                            {getMediaPreview()}
                            <br></br>
                            <Form  onSubmit={e => { e.preventDefault(); }}>
                            <Form.Group className="mb-3">
                                <Form.Label>Teks Pesan</Form.Label>
                                <br></br>
                                <textarea style={{width: "500px"}} name="input_message" onChange={onFormChange} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Tampilan Pesan</Form.Label>
                                {getMessagePreview()}
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Col sm={{ span: 12}} style={{display: "flex"}}>
                                    <Button onClick={onSubmitButtonClick} disabled={!mediaSelected() || textMessage.length === 0 || isUploading} style={styles.sendButtonStyle}>Buat Pesan</Button>
                                    {isUploading?<div className="loading-spinner" style={{marginTop: "5px", marginLeft: "10px"}}></div>:<></>}
                                    {isUploading?<div style={{marginTop: "5px", marginLeft: "10px"}}>{uploadingMessage}</div>:<></>}
                                    <Button onClick={onBack} style={{backgroundColor: appContext.colors.primaryWhite, color: appContext.colors.primaryBlue, marginLeft: "10px"}}>Kembali</Button>
                                </Col>
                            </Form.Group>
                        </Form>
                        </div>
                    </div>:<></>
                    
                }
            </div>
        </>
    )
}

export default SetupMessagePage;