import React, { createContext, useState, useEffect } from "react";
import useCheckMobileScreen from "./ResponsiveStyleCheck";

const UserData = createContext();

const UserDataProvider = (props) => {
    const twindow = typeof window !== 'undefined' ? window : {};

    const userId = localStorage.getItem("user_id");
    const userEmail = localStorage.getItem("email");
    const userPassword = localStorage.getItem("password");
    

    return (
        <UserData.Provider
        value={{
            userId,
            userEmail,
            userPassword
        }}
        >
        {props.children}
        </UserData.Provider>
    );
}
export { UserData, UserDataProvider };